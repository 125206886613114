import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '@core/authentication.service';
import { CredentialService } from '@core/credential.service';
import { ShellService } from '@core/shell.service';
import { untilDestroyed } from '@core/until-destroyed';
import * as Ladda from 'ladda';
import { MessageService } from 'primeng/api';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { RecaptchaComponent } from 'ng-recaptcha';
import { C_SITE_KEY } from '@app/app.module';
import Swal from 'sweetalert2';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NGXLogger } from 'ngx-logger';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.css']
})
export class ForgotComponent implements OnInit, OnDestroy {
  @ViewChild('captchaRef', {static: true}) reCaptcha: RecaptchaComponent;

  form: FormGroup;
  SITE_KEY = C_SITE_KEY;
  isLoading: boolean;
  captcha: string;
  email: string;

  swalWithBootstrapButtons = Swal.mixin({});

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private http: HttpClient,
    private route: ActivatedRoute,
    private logger: NGXLogger,
    private messageService: MessageService,
    private credentials: CredentialService,
    private shellService: ShellService,
    private auth: AuthenticationService
  ) {
  }

  ngOnDestroy() {
  }

  ngOnInit() {
    if (this.credentials.isAuthenticated()) {
      this.auth.logout().pipe(
        map(ret => {
          if (ret) {
            this.router.navigate(['/auth/login']).then();
          }
        })
      );
    }

    this.form = this.fb.group({
      username: ['', [Validators.required]],
      name: ['', [Validators.required, Validators.email]],
    });
  }

  requestLogin(event: string) {
    this.processCaptcha(event);
  }

  processCaptcha(captcha: string) {
    if (captcha !== null) {
      this.auth.resolvedi(captcha)
        .pipe(untilDestroyed(this))
        .subscribe((next: boolean) => {
          if (next === true) {
            this.process();
          } else {
            this.shellService.show('error', 'Erreur', 'Code captcha erroné');
          }
        });
    } else {
      this.process();
    }
  }

  process() {
    if (this.form.valid) {
      this.swalWithBootstrapButtons.fire({
        title: 'Réinitialisation en cours!',
        html: 'Patientez un instant...',
        timerProgressBar: true,
        onBeforeOpen: () => Swal.showLoading(),
        onOpen: () => {
          this.auth.forgot(this.form.get('name').value, this.form.get('username').value)
            .pipe(untilDestroyed(this))
            .subscribe(
              next => {
                const json = this.shellService.getJson(next);
                if (json.code === 0) {
                  this.router.navigate(['/auth/login']).then(value1 => {
                    this.swalWithBootstrapButtons.fire(
                      'Votre mot de passe a été réinitialisé avec succès.',
                      `Un mail contenant les nouvelles informations de connexion a été envoyé à ${this.form.get('name').value}`,
                      'success'
                    ).then();
                  });
                } else {
                  this.reCaptcha.reset();
                  this.swalWithBootstrapButtons.fire(
                    `Erreur`,
                    json.errorMessage,
                    'error'
                  );
                }
              },
              error => {
                this.reCaptcha.reset();
                this.swalWithBootstrapButtons.fire(
                  error.statusText,
                  error.message,
                  'error'
                ).then();
              },
            );
        },
        allowOutsideClick: () => !Swal.isLoading(),
      }).then(_ => {
        this.reCaptcha.reset();
      });
    }
  }

  request(): Observable<any> {
    return this.http.request('post', `/users/forgot.json`, {body: {email: this.email}})
      .pipe(
        untilDestroyed(this),
        map(response => {
          this.logger.debug(response);
          return response;
        }),
        catchError(err => {
          this.logger.error(err);
          this.router.navigate(['/admin/users']).then(value => {
            this.messageService.add({
              severity: 'error',
              summary: 'Erreur',
              detail: `Une erreur est survenue. Impossible de lire ${this.route.snapshot.toString()}`,
              sticky: true,
              closable: true
            });
          });
          return of(err);
        })
      );
  }

  forgot() {
    this.isLoading = true;
    if (!this.captcha) {
      this.isLoading = false;
      Ladda.stopAll();
      this.shellService.show('error', 'Erreur', 'Code captcha requis.');
    } else {
      this.request().subscribe(value => {
        const json = JSON.parse(JSON.stringify(value));
        if (json.code === 0) {
          this.router.navigate(['/auth/login']).then(value1 => {
            this.messageService.add({
              severity: 'success',
              summary: 'Mot de passe réinitialisé',
              detail: `Un mail contenant les nouvelles informations de connexion a été envoyé à ${this.email}.`,
              sticky: true,
              closable: true
            });
          });
        } else {
          Ladda.stopAll();
          this.shellService.show(
            'error',
            'Erreur!',
            json.errorMessage
          );
        }
      }, error => {
        this.logger.error(error);
        Ladda.stopAll();
        this.shellService.show(
          'error',
          'Erreur!',
          'Une erreur est survenue! ' + error
        );
      });
    }
  }

  resolved(captcha: string) {
    this.isLoading = true;
    this.auth.resolved(captcha)
      .pipe(
        untilDestroyed(this)
      ).subscribe(next => {
      const json = JSON.parse(JSON.stringify(next));
      if (json.code === 0 && json.captchaResponse.success) {
        this.captcha = captcha;
        this.isLoading = false;
      } else {
        this.captcha = null;
      }
    }, () => {
      this.captcha = null;
    });
  }

  login() {
    this.router.navigate(['/auth/login']).then();
  }

}
