import { Injectable, Type } from "@angular/core";
import { ActivatedRoute, Route, Router, Routes } from "@angular/router";
import { AuthenticationGuard } from "@core/authentication.guard";
import { addHours, getHours, getMinutes, getSeconds } from "date-fns";
import * as $ from "jquery";
import { LoggerService } from "@core/logger.service";
import { CredentialService } from "@core/credential.service";
import { User } from "@model/user";
import { MessageService } from "primeng/api";
import { Observable } from "rxjs";
import { tsXLXS } from "ts-xlsx-export";
import { Carte } from "@model/carte";
import { OperationSender } from "@model/operation-sender";
import { OperationReceiver } from "@model/operation-receiver";
import { environment } from "@env/environment";
import Swal from "sweetalert2";
import { Operation } from "@model/operation";
import { HttpClient } from "@angular/common/http";
import { TeacherMatiereClassroom } from "@lidar/interfaces/models/teacher-matiere-classroom";
import { Schedule } from "@lidar/interfaces/models/schedule";
import { FormGroup } from "@angular/forms";
import { Registration } from "@lidar/interfaces/models/registration";

@Injectable({
  providedIn: "root",
})
export class ShellService {
  swal = Swal.mixin({});

  constructor(
    private credentialService: CredentialService,
    private messageService: MessageService,
    private logger: LoggerService,
    private http: HttpClient,
    private router: Router
  ) { }

  /**
   * Creates routes using the shell component and authentication.
   * @param pathName The path
   * @param componentName The component name
   * @param routes The routes to add.
   * @return The new route using shell as the base.
   */
  static childRoutes(
    pathName: string,
    componentName: Type<any>,
    routes: Routes
  ): Route {
    return {
      path: pathName,
      component: componentName,
      children: routes,
      canActivate: [AuthenticationGuard],
      data: { reuse: true },
    };
  }

  getRouteUrl(route: ActivatedRoute): string {
    return route.pathFromRoot
      .filter((a) => a.routeConfig !== null && a.routeConfig.path.length > 0)
      .reduce((a, b) => a + "/" + b.routeConfig.path, "");
  }

  autoRang(registrations: Registration[], tmId: number, m: number, periodeId: number): string {
    let listOfMoyennes: Array<number> = [];

    registrations.forEach((reg: Registration) => {
      reg.bulletin_notes.forEach(bn => {
        if (Number(bn.periode_id) === periodeId) {
          bn.moyennes.forEach(moyenne => {
            if (Number(moyenne.teacher_matiere_id) === tmId) {
              if (Number(moyenne.non_classe) === 0) {
                if (Number(moyenne.teacher_matiere.matiere.semestre) === 0 || Number(moyenne.teacher_matiere.matiere.semestre) === periodeId) {
                  listOfMoyennes.push(moyenne.name);
                }
              }
            }
          })
        }
      });
    });

    listOfMoyennes.sort((x, y) => {
      return y - x;
    });

    var duplicates = listOfMoyennes.reduce(function (acc, el, i, arr) {
      if (arr.indexOf(el) !== i && acc.indexOf(el) < 0) acc.push(el); return acc;
    }, []);

    const md = duplicates.indexOf(m);

    const r = listOfMoyennes.indexOf(m) + 1;
    const i = r < 2 ? 'er' : 'e';

    const rang = `${r}${i}${(md === -1 ? '' : ' ex')}`;

    return rang;
  }

  autoClassement(moyennes: Array<number>, m: number): string {
    // Trier les moyennes par ordre decroissant
    moyennes.sort((x, y) => {
      return y - x;
    });

    // On stoque dans une variable, toutes les moyennes duppliquees 
    var duplicates = moyennes.reduce(function (acc, el, i, arr) {
      if (arr.indexOf(el) !== i && acc.indexOf(el) < 0) acc.push(el); return acc;
    }, []);

    // On recupere l'index de la moyenne de l'etudiant si elle existe dans les moyennes duppliquees. Sinon retourne -1
    const md = duplicates.indexOf(m);

    // Rang
    const r = (moyennes.indexOf(m) + 1);

    // er ou e
    const i = r < 2 ? 'er' : 'e';

    // execo ou pas
    const rang = `${r}${i}${(md === -1 ? '' : ' ex')}`;

    return rang;
  }

  autoAppreciation(f: FormGroup, moyenne: number) {
    // On defini les appreciations
    if (moyenne < 6) {
      f.get('moyenne').get('appreciation').setValue('Médiocre');
    }
    if (moyenne >= 6 && moyenne < 8) {
      f.get('moyenne').get('appreciation').setValue('Faible');
    }
    if (moyenne >= 8 && moyenne < 10) {
      f.get('moyenne').get('appreciation').setValue('Insuffisant');
    }
    if (moyenne >= 10 && moyenne < 12) {
      f.get('moyenne').get('appreciation').setValue('Passable');
    }
    if (moyenne >= 12 && moyenne < 14) {
      f.get('moyenne').get('appreciation').setValue('Assez-Bien');
    }
    if (moyenne >= 14 && moyenne < 16) {
      f.get('moyenne').get('appreciation').setValue('Bien');
    }
    if (moyenne >= 16 && moyenne < 18) {
      f.get('moyenne').get('appreciation').setValue('Très-Bien');
    }
    if (moyenne >= 18) {
      f.get('moyenne').get('appreciation').setValue('Excellent');
    }
  }

  CDAutoAppreciation(moyenne: number): string {
    // On defini les appreciations
    if (moyenne === null) {
      return `Non classé`;
    }
    if (moyenne < 6) {
      return `Travail médiocre`;
    }
    if (moyenne >= 6 && moyenne < 8) {
      return `Travail faible`;
    }
    if (moyenne >= 8 && moyenne < 10) {
      return `Travail insuffisant`;
    }
    if (moyenne >= 10 && moyenne < 12) {
      return `Travail passable`;
    }
    if (moyenne >= 12 && moyenne < 14) {
      return `Assez bon travail`;
    }
    if (moyenne >= 14 && moyenne < 16) {
      return `Bon travail`;
    }
    if (moyenne >= 16 && moyenne < 18) {
      return `Très bon travail`;
    }
    if (moyenne >= 18) {
      return `Excellent travail`;
    }
  }

  calculateMoyenne(periodeId, teacherMatiereClassroom: TeacherMatiereClassroom, coefficient?: number): number {
    let coef = 0;
    let moyenne = 0;

    teacherMatiereClassroom.schedules.forEach((schedule: Schedule) => {
      if (Number(periodeId) === schedule.periode_id) {
        if (schedule.type !== 'cours') {
          schedule.notes.forEach((note: any) => {
            const _coef = schedule.coef || 1;
            // Si la note n'est pas définie, on attribue par défaut 0
            moyenne += (note.name || 0) * _coef;
            coef += _coef;
          });
        }
      }
    });

    return moyenne / (coef === 0 ? 1 : coef);
  }

  convertToDuration(secondsAmount: number) {
    let hours = 0;
    let minutes = 0;

    minutes = Math.floor(secondsAmount / 60);
    hours = Math.floor(minutes / 60);
    minutes = Math.ceil(secondsAmount % 60);

    return `${hours <= 0 ? "00" : hours <= 9 ? "0" + hours : hours}:${minutes <= 0 ? "00" : minutes <= 9 ? "0" + minutes : minutes
      }`;
  }

  getSenderName(
    user: User,
    sender: OperationSender,
    anonyme?: any,
    operation?: Operation
  ): string {
    if (sender === null) {
      if (anonyme !== null && anonyme !== undefined) {
        const json = JSON.parse(JSON.stringify(anonyme));
        return `${json.nom} ${json.prenoms}`;
      }
      if (operation.parent_operation !== null) {
        return this.getSenderName(
          user,
          operation.parent_operation.operation_sender,
          operation.parent_operation.operation_anonyme
        );
      }
      return ``;
    }

    if (sender.carte.bank_carte !== null) {
      return sender.carte.bank_carte.bank.name;
    }

    if (sender.carte.entite_carte !== null) {
      if (this.credentialService.isEntite()) {
        return `${user.nom} ${user.prenoms}`;
      }
      return sender.carte.entite_carte.entite.name;
    }

    if (sender.carte.agence_carte !== null) {
      if (this.credentialService.isAgence()) {
        return `${user.nom} ${user.prenoms}`;
      }
      return sender.carte.agence_carte.agence.name;
    }

    if (sender.carte.distributeur_carte !== null) {
      return sender.carte.distributeur_carte.distributeur.name;
    }

    if (sender.carte.marchand_carte !== null) {
      return sender.carte.marchand_carte.marchand.name;
    }

    if (sender.carte.fournisseur_carte !== null) {
      return sender.carte.fournisseur_carte.fournisseur.name;
    }

    if (sender.carte.beneficiaire_carte !== null) {
      return `${sender.carte.beneficiaire_carte.beneficiaire.nom} ${sender.carte.beneficiaire_carte.beneficiaire.prenoms}`;
    }

    if (this.credentialService.isMulti()) {
      return `${user.nom} ${user.prenoms}`;
    }

    return `Lidar`;
  }

  getReceiverName(
    user: User,
    receiver: OperationReceiver,
    anonyme?: any,
    operation?: Operation
  ): string {
    if (receiver === null) {
      if (anonyme !== null && anonyme !== undefined) {
        const json = JSON.parse(JSON.stringify(anonyme));
        return `${json.nom} ${json.prenoms}`;
      }
      if (operation.parent_operation !== null) {
        return this.getReceiverName(
          user,
          operation.parent_operation.operation_receiver,
          operation.parent_operation.operation_anonyme
        );
      }
      return ``;
    }

    if (receiver.carte.bank_carte !== null) {
      return receiver.carte.bank_carte.bank.name;
    }

    if (receiver.carte.entite_carte !== null) {
      return receiver.carte.entite_carte.entite.name;
    }

    if (receiver.carte.agence_carte !== null) {
      return receiver.carte.agence_carte.agence.name;
    }

    if (receiver.carte.distributeur_carte !== null) {
      return receiver.carte.distributeur_carte.distributeur.name;
    }

    if (receiver.carte.marchand_carte !== null) {
      return receiver.carte.marchand_carte.marchand.name;
    }

    if (receiver.carte.fournisseur_carte !== null) {
      return receiver.carte.fournisseur_carte.fournisseur.name;
    }

    if (receiver.carte.beneficiaire_carte !== null) {
      return `${receiver.carte.beneficiaire_carte.beneficiaire.nom} ${receiver.carte.beneficiaire_carte.beneficiaire.prenoms}`;
    }

    if (this.credentialService.isMulti()) {
      return `Lidar`;
    }

    return ``;
  }

  checkAll(
    event: any,
    listOfCheckboxes: any,
    listOfDatas: any[],
    label: any,
    labelIcon: any
  ): any[] {
    const state = !(listOfCheckboxes.length === listOfDatas.length);

    listOfDatas = [];

    if (state) {
      listOfCheckboxes.each((index, element) => {
        // this.logger.debug($(element).val(), element);
        listOfDatas.push(+$(element).val());
      });
    }

    label.text(!state ? `Tout cocher` : "Tout décocher");
    labelIcon
      .removeClass("os-icon-check-square")
      .removeClass("os-icon-square")
      .addClass(!state ? "os-icon-check-square" : "os-icon-square");

    this.logger.debug(listOfDatas);
    return listOfDatas;
  }

  isGranted(roles: string[]): boolean {
    const data: User = this.credentialService.credentials.user;
    if (data !== null) {
      const userRoles = this.credentialService.credentials.roles;
      if (data.profile.parent_id === null) {
        return true;
      }
      userRoles.forEach((role: string) => {
        roles.forEach((action: string) => {
          if (role === action) {
            return true;
          }
        });
      });
    }
    return false;
  }

  parsePerms(roles: string[]) {
    if (!this.isGranted(roles)) {
      this.router.navigate(["/"]).then((value) => {
        this.show(
          "error",
          "Accès refusé!",
          `Vos permissions actuelles ne vous permettent pas d'accéder à cette page.`
        );
      });
    }
  }

  listOfCards(start: string, end: string): string[] {
    const cards: string[] = [];
    const s = start.substring(start.length - 5, start.length);
    const e = end.substring(end.length - 5, end.length);

    /*
    for (let i = parseInt(start, 10); i <= parseInt(end, 10); i++) {
      cards.push(i.toString().padStart(9, '0'));
    }*/
    for (let i = parseInt(s, 10); i <= parseInt(e, 10); i++) {
      cards.push(i.toString().padStart(9, "0"));
    }
    return cards;
  }

  getMask(data: string, numberToShow: number = 5) {
    const show = data
      .trim()
      .substring(data.trim().length, data.trim().length - numberToShow);
    return show.padStart(data.trim().length, "*");
    /*return data;     */
  }

  getPhoneMask(data: string) {
    // return data.substring(0, 4) + ' ' + data.substring(4, 6) + this.getMask(data.substring(6), 2);
    return data;
  }

  getEmailMask(data: string) {
    /*
    const datas: string[] = data.split('@');
    if (datas.length === 2) {
      return datas[0].substring(0, 3) + this.getMask(datas[0].substring(3, datas[0].length), 2) + '@' + datas[1];
    }*/
    return data;
  }

  show(type: string, title: any, message: any, forceClear: boolean = false) {
    if (forceClear) {
      this.messageService.clear();
    }
    this.messageService.add({
      severity: type,
      summary: title,
      detail: message,
    });
  }

  getJson(object: any): any {
    return JSON.parse(JSON.stringify(object));
  }

  getDetailBtn(data: any) {
    const icon = `<i class="os-icon os-icon-search"></i>`;
    return `<button type="button" data-value="${data.id}" class="btn btn-secondary btn-sm" id="go">${icon}</button>`;
  }

  getQrCodeBtn(data: any) {
    const icon = `<i class="fa fa-qrcode mr-2"></i>`;
    const link = `${environment.serverBase}/pages/qrcode?cid=${data.id}`;
    return `<a href="${link}" target="_blank" class="btn btn-link ui-button-raised" id="go" download>${icon}Voir code QR</a>`;
  }

  getSolde(carte: Carte): number {
    let receivers = 0;
    let senders = 0;

    if (carte) {
      if (carte.operation_senders.length > 0) {
        carte.operation_senders.forEach((operationSender: OperationSender) => {
          senders += operationSender.operation.montant;
        });
      }
      if (carte.operation_receivers.length > 0) {
        carte.operation_receivers.forEach(
          (operationReceiver: OperationReceiver) => {
            receivers += operationReceiver.operation.montant;
          }
        );
      }
    }
    return Math.abs(receivers - senders);
  }

  export(observable: Observable<any[]>, fileName: string) {
    observable.subscribe((next) => {
      if (next.length > 0) {
        tsXLXS().exportAsExcelFile(next).saveAsExcelFile(fileName);
      } else {
        this.messageService.add({
          severity: "info",
          summary: "Information !",
          detail: `Il n'y a aucun tableau a exporter.`,
        });
      }
    });
  }

  getRemoteSolde(
    carteId: number,
    level = 0,
    both = true,
    unlike = null
  ): Observable<any> {
    const json = JSON.parse(JSON.stringify({ carte_id: carteId }));

    if (unlike !== null) {
      json.unlike = unlike;
    }

    if (both) {
      if (this.credentialService.isAgence()) {
        json.both = true;
        json.unlike = "6,7";
      }
    } else {
      json.level = level;
    }

    return this.http.post(`/operations/solde.json`, json);
  }
}
