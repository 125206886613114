<div class="avatar-w">
  <img alt="" src="assets/img/avatar.png">
</div>

<div class="logged-user-info-w">
  <div class="logged-user-name">
    {{credential.user.nom | uppercase}} {{credential.user.prenoms}}
  </div>
  <div class="logged-user-role">
    {{credential.user.profile.name}}
  </div>
</div>
