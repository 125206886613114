<ul class="main-menu">
  <ng-container *ngFor="let menu of items">
    <ng-container *ngFor="let item of menu.items">
      <li [ngClass]="{'has-sub-menu': item.items && item.items.length > 0}">
        <ng-container *ngIf="item.link else rel">
          <a [routerLink]="item.link">
            <ng-container [ngTemplateOutlet]="relitem" [ngTemplateOutletContext]="{data: item}"></ng-container>
          </a>
        </ng-container>
        <ng-template #rel>
          <a href="javascript:;">
            <ng-container [ngTemplateOutlet]="relitem" [ngTemplateOutletContext]="{data: item}"></ng-container>
          </a>
        </ng-template>
        <ul *ngIf="item.items && item.items.length > 0" class="sub-menu">
          <li *ngFor="let subitem of item.items">
            <a [href]="subitem.link">{{subitem.label}}</a>
          </li>
        </ul>
      </li>
    </ng-container>
  </ng-container>
</ul>

<ng-template let-item="data" #relitem>
  <div *ngIf="item.icon" class="icon-w">
    <div [class]="item.icon"></div>
  </div>
  <span>{{item.label}}</span>
</ng-template>
