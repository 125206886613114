import { DatePipe, registerLocaleData, UpperCasePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { AuthModule } from '@auth/auth.module';
import { CoreModule } from '@core/core.module';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import {
  RECAPTCHA_LANGUAGE,
  RECAPTCHA_SETTINGS,
  RecaptchaSettings,
} from 'ng-recaptcha';
import { DaterangepickerConfig } from 'ng2-daterangepicker';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { LoggerTestingModule } from 'ngx-logger/testing';
import { MessageService } from 'primeng/api';
import { CheckboxModule } from 'primeng/checkbox';
import { ToastModule } from 'primeng/toast';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

export const C_SITE_KEY = '6LeumGcaAAAAAMfZ4reXrPZSBQbEtGLFfkpkTsYa';

registerLocaleData(localeFr, 'fr');

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    AuthModule,

    HttpClientModule,
    RouterModule,
    ToastModule,
    CheckboxModule,
    AppRoutingModule,
    SweetAlert2Module.forRoot(),

    LoggerModule.forRoot({
      level: NgxLoggerLevel.DEBUG,
      disableConsoleLogging: false,
    }),

    LoggerTestingModule,
  ],
  providers: [
    { provide: RECAPTCHA_LANGUAGE, useValue: 'fr' },
    {
      provide: RECAPTCHA_SETTINGS,
      // useValue: { siteKey: '6Ld1h74UAAAAAGayyMugsoZEvbyBx1zW3Cc5wval' } as RecaptchaSettings,
      useValue: {
        siteKey: C_SITE_KEY,
      } as RecaptchaSettings,
    },
    DaterangepickerConfig,
    MessageService,
    UpperCasePipe,
    DatePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
