import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { User } from '@model/user';

export interface Credentials {
  token: string;
  user: User;
  roles: string[];
  timestamp: string;
}

const credentialsKey = 'dunatic_credentials';

@Injectable({
  providedIn: 'root'
})
export class CredentialService {

  // tslint:disable-next-line:variable-name
  private _credentials: Credentials | null = null;

  constructor() {
    const savedCredentials = sessionStorage.getItem(credentialsKey) || localStorage.getItem(credentialsKey);
    if (savedCredentials) {
      this._credentials = JSON.parse(savedCredentials);
    }
  }

  /**
   * Checks is the user is authenticated.
   * @return True if the user is authenticated.
   */
  isAuthenticated(): boolean {
    return !!this.credentials;
  }

  isMulti(): boolean {
    return this._credentials.user.agence_user === null
      && this._credentials.user.entite_user === null;
  }

  isEntite(): boolean {
    return this._credentials.user.entite_user !== null;
  }

  isAgence(): boolean {
    return this._credentials.user.agence_user !== null;
  }

  /**
   * Gets the user credentials.
   * @return The user credentials or null if the user is not authenticated.
   */
  get credentials(): Credentials | null {
    return this._credentials;
  }

  /**
   * Sets the user credentials.
   * The credentials may be persisted across sessions by setting the `remember` parameter to true.
   * Otherwise, the credentials are only persisted for the current session.
   * @param credentials The user credentials.
   * @param remember True to remember credentials across sessions.
   */
  setCredentials(credentials?: Credentials, remember?: boolean) {
    this._credentials = credentials || null;

    if (credentials) {
      credentials.timestamp = moment().toISOString();
      const storage = remember ? localStorage : sessionStorage;
      storage.setItem(credentialsKey, JSON.stringify(credentials));
    } else {
      sessionStorage.removeItem(credentialsKey);
      localStorage.removeItem(credentialsKey);
    }
  }

}
