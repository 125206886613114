import { Component, Input, OnInit } from '@angular/core';
import { AuthorizationService } from '@authorization/authorization.service';
import { LidarMenu } from '@core/api';

@Component({
  selector: 'app-lidar-menu',
  templateUrl: './lidar-menu.component.html',
  styleUrls: ['./lidar-menu.component.css']
})
export class LidarMenuComponent implements OnInit {

  @Input() items: LidarMenu[] = [];

  constructor(
    private authorizationService: AuthorizationService,
  ) { }

  ngOnInit() {
  }

}
