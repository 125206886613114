import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-logo-buttons',
  templateUrl: './logo-buttons.component.html',
  styleUrls: ['./logo-buttons.component.css']
})
export class LogoButtonsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
