import { Component, Input, OnInit } from '@angular/core';
import { LidarMenu } from '@core/api';

@Component({
  selector: 'app-lidar-menu-mobile',
  templateUrl: './lidar-menu-mobile.component.html',
  styleUrls: ['./lidar-menu-mobile.component.css']
})
export class LidarMenuMobileComponent implements OnInit {

  @Input() items: LidarMenu[] = [];

  constructor() { }

  ngOnInit() {
  }

}
