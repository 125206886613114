import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthComponent } from './auth.component';
import { ForgotComponent } from './forgot/forgot.component';
import { InviteComponent } from './invite/invite.component';
import { LoginComponent } from './login/login.component';
import { NoaccessComponent } from './noaccess/noaccess.component';
import { SigninComponent } from './signin/signin.component';
import { Signinv2Component } from './signinv2/signinv2.component';

const routes: Routes = [
  {
    path: 'auth',
    component: AuthComponent,
    children: [
      { path: 'login_old', component: SigninComponent },
      { path: 'invite/:email/:token', component: InviteComponent },
      { path: 'signin', component: LoginComponent },
      { path: 'login', component: Signinv2Component },
      { path: 'forgot', component: ForgotComponent },
      { path: 'noaccess', component: NoaccessComponent },
      { path: '', redirectTo: 'login', pathMatch: 'full' }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
