import { Component, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { NGXLogger } from 'ngx-logger';
import Swal from 'sweetalert2';

export const LANG_DATATABLES_FR = {
  processing: 'Traitement en cours...',
  search: 'Rechercher&nbsp;:',
  lengthMenu: 'Afficher _MENU_ &eacute;l&eacute;ments',
  info: 'Affichage de l\'&eacute;lement _START_ &agrave; _END_ sur _TOTAL_ &eacute;l&eacute;ments',
  infoEmpty: 'Affichage de 0 &eacute;l&eacute;ment',
  infoFiltered: '(filtr&eacute; de _MAX_ &eacute;l&eacute;ments au total)',
  infoPostFix: '',
  loadingRecords: 'Chargement en cours...',
  zeroRecords: 'Aucun &eacute;l&eacute;ment &agrave; afficher',
  emptyTable: 'Aucune donnée disponible dans le tableau',
  paginate: {
    first: 'Premier',
    previous: 'Pr&eacute;c&eacute;dent',
    next: 'Suivant',
    last: 'Dernier'
  },
  aria: {
    sortAscending: ': activer pour trier la colonne par ordre croissant',
    sortDescending: ': activer pour trier la colonne par ordre décroissant'
  }
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  swalWithBootstrapButtons = Swal.mixin({
    allowOutsideClick: false,
    allowEnterKey: false,
    allowEscapeKey: false,
  });

  title = 'Plateforme Lidar';

  constructor(
    private logger: NGXLogger,
  ) {
  }

  ngOnInit(): void {
    this.logger.debug('Ok');
  }

}
