import { MessageService } from 'primeng/api';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable()
export class ErrorHandlerService implements ErrorHandler {

  constructor(
    private messageService: MessageService,
    private injector: Injector,
  ) {
  }

  handleError(error: any): void {
    console.error(error);
    if (error instanceof HttpErrorResponse) {
      const router = this.injector.get(Router);
      if (error.status === 401) {
        router.navigate(['/auth/login'], { replaceUrl: true }).then();
      } else {
        console.error(' >> Erreur détectée');
        console.error(error.error.message);
        this.messageService.add({ severity: 'error', summary: 'Souclou Digital', detail: error.error.message });
      }
    }
  }

}
