<ul class="main-menu">
  <ng-container *ngFor="let menu of items">
    <li class="sub-header">
      <span>{{menu.label}}</span>
    </li>

    <ng-container *ngIf="menu.items && (menu.items.length > 0)">

      <ng-container *ngFor="let menuitem of menu.items">
        <li [ngClass]="{'has-sub-menu': menuitem.items && (menuitem.items.length > 0)}" routerLinkActive="selected">

          <ng-container *ngIf="menuitem.link else rel">
            <a [routerLink]="menuitem.link">
              <ng-container [ngTemplateOutlet]="relitem"
                            [ngTemplateOutletContext]="{data: menuitem}"></ng-container>
            </a>
          </ng-container>

          <ng-template #rel>
            <a href="javascript:;">
              <ng-container [ngTemplateOutlet]="relitem"
                            [ngTemplateOutletContext]="{data: menuitem}"></ng-container>
            </a>
          </ng-template>

          <div *ngIf="menuitem.items && (menuitem.items.length > 0)" class="sub-menu-w">
            <div class="sub-menu-header">
              {{menuitem.label}}
            </div>
            <div class="sub-menu-icon">
              <i [class]="menuitem.icon"></i>
            </div>
            <div class="sub-menu-i">
              <ul class="sub-menu">
                <li *ngFor="let subitem of menuitem.items">
                  <a [routerLink]="subitem.link">{{subitem.label}}</a>
                </li>
              </ul>
            </div>
          </div>
        </li>
      </ng-container>

    </ng-container>
  </ng-container>
</ul>

<ng-template let-item="data" #relitem>
  <div *ngIf="item.icon" class="icon-w">
    <div [class]="item.icon"></div>
  </div>
  <span>{{item.label}}</span>
</ng-template>
