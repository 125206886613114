import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CanProfileAccessDirective } from '@authorization/can-profile-access.directive';
import { CanMasterAccessDirective } from '@authorization/can-master-access.directive';
import { CanAdminAccessDirective } from '@authorization/can-admin-access.directive';
import { CanActionAccessDirective } from '@authorization/can-action-access.directive';
import { RequireAdminAccessDirective } from '@authorization/require-admin-access.directive';
import { RequireMasterAccessDirective } from '@authorization/require-master-access.directive';
import { RequireProfileAccessDirective } from '@authorization/require-profile-access.directive';
import { RequireActionAccessDirective } from '@authorization/require-action-access.directive';

const DIRECTIVES = [
  CanAdminAccessDirective,
  CanMasterAccessDirective,
  CanActionAccessDirective,
  CanProfileAccessDirective,
  RequireAdminAccessDirective,
  RequireMasterAccessDirective,
  RequireProfileAccessDirective,
  RequireActionAccessDirective,
];

@NgModule({
  declarations: [
    ...DIRECTIVES
  ],
  exports: [
    ...DIRECTIVES
  ],
  imports: [
    CommonModule
  ]
})
export class AuthorizationModule { }
