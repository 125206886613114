import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CredentialService } from '@core/credential.service';
import { User } from '@model/user';
import { Observable } from 'rxjs';

import { Form } from '../form';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.css']
})
export class UserFormComponent implements OnInit, Form<User> {

  isLoading = false;
  form: FormGroup;
  data: User;
  data$: Observable<User>;
  datas$: Observable<User[]>;

  constructor(
    private fb: FormBuilder,
    private cred: CredentialService,
  ) { }

  onCreate(event: any): void {
    throw new Error('Method not implemented.');
  }
  onRead(event: any): void {
    throw new Error('Method not implemented.');
  }
  onEdit(event: any): void {
    throw new Error('Method not implemented.');
  }
  onDelete(event: any): void {
    throw new Error('Method not implemented.');
  }

  onCheckChange() {
    this.form.controls.password.setValue('');
    this.form.clearValidators();
  }

  ngOnInit() {
    this.form = this.fb.group({
      usr: [false],
      pwd: [true],
      statut: [1],
      parent_id: [this.cred.credentials.user.id],
      profile: ['', Validators.required],
      nom: ['', Validators.required],
      prenoms: ['', Validators.required],
      username: ['', [Validators.required, Validators.minLength(3)]],
      password: ['', [Validators.minLength(6)]],
      email: ['', [Validators.required, Validators.email]],
    });
  }

}
