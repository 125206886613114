<div class="auth-box-w">
  <div class="logo-w">
    <a routerLink="/">
      <img
        width="90"
        src="assets/img/logo3.png"
      >
    </a>
  </div>

  <re-captcha
    size="invisible"
    #captchaRef="reCaptcha"
    [siteKey]="SITE_KEY"
    (resolved)="$event && save($event)"></re-captcha>

  <ng-container *ngIf="invite | async as invitation else globalLoader">
    <h4 class="auth-header">
      Création de compte
    </h4>
    <form
      [formGroup]="form"
      (ngSubmit)="captchaRef.execute()"
      novalidate
    >

      <div
        class="form-group"
        [ngClass]="{'has-error has-danger': (email.dirty || email.touched) && email.invalid}"
      >
        <label
          for="email"
          translate="yes"
          i18n
        >
          Votre E-mail
        </label>
        <input
          class="form-control"
          formControlName="email"
          id="email"
          name="email"
          pInputText
          required
        >
        <div
          class="help-block form-text with-errors form-control-feedback"
          *ngIf="(email.dirty || email.touched) && email.invalid"
        >
          <ul class="list-unstyled">
            <li *ngIf="email.errors.required">Champs obligatoire</li>
            <li *ngIf="email.errors.unique">
              {{form.controls.email.value}} est déjà utilisé
            </li>
          </ul>
        </div>
      </div>

      <div
        class="form-group"
        [ngClass]="{'has-error has-danger': (nom.dirty || nom.touched) && nom.invalid}"
      >
        <label
          for="nom"
          translate="yes"
          i18n
        >
          Nom
        </label>
        <input
          class="form-control"
          formControlName="nom"
          id="nom"
          name="nom"
          pInputText
          placeholder="Saisir votre nom de famille"
          required
        >
        <div
          class="help-block form-text with-errors form-control-feedback"
          *ngIf="(nom.dirty || nom.touched) && nom.invalid"
        >
          <ul class="list-unstyled">
            <li *ngIf="nom.errors.required">Champs obligatoire</li>
            <li *ngIf="nom.errors.unique">
              {{nom.value}} est déjà utilisé
            </li>
          </ul>
        </div>
      </div>

      <div
        class="form-group"
        [ngClass]="{'has-error has-danger': (prenoms.dirty || prenoms.touched) && prenoms.invalid}"
      >
        <label
          for="prenoms"
          translate="yes"
          i18n
        >
          Prénoms
        </label>
        <input
          class="form-control"
          formControlName="prenoms"
          id="prenoms"
          name="prenoms"
          pInputText
          placeholder="Saisir vos prénoms"
          required
        >
        <div
          class="help-block form-text with-errors form-control-feedback"
          *ngIf="(prenoms.dirty || prenoms.touched) && prenoms.invalid"
        >
          <ul class="list-unstyled">
            <li *ngIf="prenoms.errors.required">Champs obligatoire</li>
          </ul>
        </div>
      </div>

      <div
        class="form-group"
        [ngClass]="{'has-error has-danger': (username.dirty || username.touched) && username.invalid}"
      >
        <label
          for="username"
          translate="yes"
          i18n
        >
          Nom d'utilisateur
        </label>
        <input
          class="form-control"
          formControlName="username"
          id="username"
          name="username"
          pInputText
          placeholder="Saisir votre nom d'utilisateur"
          required
        >
        <div
          class="help-block form-text with-errors form-control-feedback"
          *ngIf="(username.dirty || username.touched) && username.invalid"
        >
          <ul class="list-unstyled">
            <li *ngIf="username.errors.required">Champs obligatoire</li>
            <li *ngIf="username.errors.unique">
              {{form.controls.username.value}} est déjà utilisé
            </li>
          </ul>
        </div>
      </div>

      <div
        class="form-group"
        [ngClass]="{'has-error has-danger': (password.dirty || password.touched) && password.invalid}"
      >
        <label
          for="password"
          translate="yes"
          i18n
        >
          Mot de passe
        </label>
        <input
          class="form-control"
          formControlName="password"
          id="password"
          name="password"
          type="password"
          pPassword
          pInputText
          placeholder="Saisir un Mot de passe"
          promptLabel="Saisir un Mot de passe"
          weakLabel="Faible"
          strongLabel="Fort"
          mediumLabel="Moyen"
          required
        >
        <div
          class="help-block form-text with-errors form-control-feedback"
          *ngIf="(password.dirty || password.touched) && password.invalid"
        >
          <ul class="list-unstyled">
            <li *ngIf="password.errors.required">Champs obligatoire</li>
            <li *ngIf="password.errors.minlength">6 caractères minimum sont requis</li>
          </ul>
        </div>
      </div>

      <div
        class="form-group"
        [ngClass]="{'has-error has-danger': (passwordR.dirty || passwordR.touched) && passwordR.invalid}"
      >
        <label
          for="passwordR"
          translate="yes"
          i18n
        >
          Repétez Mot de passe
        </label>
        <input
          class="form-control"
          formControlName="passwordR"
          id="passwordR"
          name="passwordR"
          type="password"
          pPassword
          pInputText
          placeholder="Repétez"
          promptLabel="Repétez Mot de passe"
          weakLabel="Faible"
          strongLabel="Fort"
          mediumLabel="Moyen"
          required
        >
        <div
          class="help-block form-text with-errors form-control-feedback"
          *ngIf="(passwordR.dirty || passwordR.touched) && passwordR.invalid"
        >
          <ul class="list-unstyled">
            <li *ngIf="passwordR.errors.required">Champs obligatoire</li>
            <li *ngIf="passwordR.errors.minlength">6 caractères minimum sont requis</li>
            <li *ngIf="passwordR.errors.mustMatch">
              Les mots de passe doivent être identiques
            </li>
          </ul>
        </div>
      </div>

      <div class="buttons-w">
        <button
          [disabled]="form.invalid"
          type="submit"
          class="btn btn-secondary text-uppercase p-2"
          style="font-weight: bold;"
        >
          Créer mon compte
        </button>
      </div>

      <div class="buttons-w mt-3 text-center">
        <button
          type="button"
          class="btn btn-link"
          (click)="goLogin()"
        >
          Page de connexion
        </button>
      </div>
    </form>

  </ng-container>

  <ng-template #globalLoader>
    <div class="row">
      <div class="col-12 mb-5 text-center">
        <p-progressSpinner
          [style]="{width: '50px', height: '50px'}"
          strokeWidth="8"
          fill="#EEEEEE"
          animationDuration=".5s"
        ></p-progressSpinner>
      </div>
    </div>
  </ng-template>

</div>
