import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthorizationService } from './authorization.service';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[requireActionAccess]'
})
export class RequireActionAccessDirective implements OnInit {

  @Input('requireActionAccess') perms: string[] = [];

  constructor(
    private authorization: AuthorizationService,
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<any>,
  ) { }

  ngOnInit(): void {
    this.apply();
  }

  private clear() {
    this.viewContainer.clear();
  }

  private show() {
    this.viewContainer.createEmbeddedView(this.templateRef);
  }

  private apply(): void {
    if (this.perms.length > 0) {
      this.authorization.canAccess(this.perms) ?
        this.show() :
        this.clear();
    } else {
      this.show();
    }
  }

}
