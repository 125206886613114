import { Agence } from '@model/agence';
import { EntiteCarte } from '@model/entite-carte';
import { EntiteGlobalPerm } from '@model/entite-global-perm';
import { EntiteProfile } from '@model/entite-profile';
import { EntiteResponsable } from '@model/entite-responsable';
import { EntiteUser } from '@model/entite-user';
import { EntiteCorporateModule } from './entite-corporate-module';

import { Product } from './product';
import { UserInvite } from '@model/user-invite';

export class Entite {
  id: number;
  name: string;
  rccm: string;
  adresse: string;
  alias: string;
  statut: number;
  created: string;
  updated: string;
  // tslint:disable-next-line: variable-name
  entite_carte: EntiteCarte;
  agences: Agence[];
  // tslint:disable-next-line: variable-name
  entite_responsable: EntiteResponsable;
  // tslint:disable-next-line: variable-name
  entite_profiles: EntiteProfile[];
  // tslint:disable-next-line: variable-name
  entite_users: EntiteUser[];
  // tslint:disable-next-line: variable-name
  entite_global_perm: EntiteGlobalPerm;
  products: Product[];
  // tslint:disable-next-line: variable-name
  entite_corporate_modules: EntiteCorporateModule[];
  // tslint:disable-next-line: variable-name
  user_invites: UserInvite[];
}
