import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export abstract class AbstractResolver<T> implements Resolve<T> {
  abstract resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<T> | T;
}
