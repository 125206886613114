import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthorizationService } from './authorization.service';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[requireMasterAccess]'
})
export class RequireMasterAccessDirective implements OnInit {

  @Input('requireMasterAccess') required = false;

  constructor(
    private authorization: AuthorizationService,
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<any>,
  ) {
  }

  ngOnInit(): void {
    this.apply();
  }

  private clear() {
    this.viewContainer.clear();
  }

  private show() {
    this.viewContainer.createEmbeddedView(this.templateRef);
  }

  private apply(): void {
    if (this.required) {
      if (!this.authorization.isMaster()) {
        this.clear();
      } else {
        this.show();
      }
    } else {
      this.show();
    }
  }

}
