import { Injectable } from '@angular/core';
import { CredentialService } from '@core/credential.service';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {

  constructor(
    private credentialService: CredentialService,
  ) { }

  private isMulti(): boolean {
    return this.credentialService.isMulti();
  }

  private isEntite(): boolean {
    return this.credentialService.isEntite();
  }

  private isAgence(): boolean {
    return this.credentialService.isAgence();
  }

  public isAdmin(): boolean {
    return this.credentialService.credentials.user.profile.name.toLowerCase() === 'admin';
  }

  public isMaster(): boolean {
    return this.credentialService.credentials.user.parent_id === null && this.isAdmin();
  }

  public canAccess(perms: Array<string>): boolean {
    if (this.isAdmin()) {
      return true;
    }

    let pass = false;
    if (this.credentialService.credentials.roles) {
      if (this.credentialService.credentials.roles.length > 0) {
        this.credentialService.credentials.roles.forEach((role: string) => {
          if (perms.length > 0) {
            if (perms.includes(role)) {
              pass = true;
            }
          }
        });
      }
    }

    return pass;
  }

  public canProfileAccess(profiles: Array<string>): boolean {
    let pass = false;
    profiles.forEach(profile => {
      switch (profile) {
        case 'AGENCE':
          if (this.isAgence()) {
            pass = true;
          }
          break;
        case 'ENTITE':
          if (this.isEntite()) {
            pass = true;
          }
          break;
        case 'MULTI':
          if (this.isMulti()) {
            pass = true;
          }
          break;
      }
    });
    return pass;
  }

}
