import { Component, OnInit } from '@angular/core';
import {Credentials, CredentialService} from '@core/credential.service';

@Component({
  selector: 'app-logged-user-info',
  templateUrl: './logged-user-info.component.html',
  styleUrls: ['./logged-user-info.component.css']
})
export class LoggedUserInfoComponent implements OnInit {

  credential: Credentials;

  constructor(
    private credentialService: CredentialService
  ) { }

  ngOnInit() {
    this.credential = this.credentialService.credentials;
  }

}
