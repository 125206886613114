import { Component, OnInit } from '@angular/core';
import { LidarMenu } from '@core/api';
import { Credentials, CredentialService } from '@core/credential.service';
import { MENU } from '@shared/main-menu/main-menu.component';

@Component({
  selector: 'app-menu-an-user',
  templateUrl: './menu-an-user.component.html',
  styleUrls: ['./menu-an-user.component.css']
})
export class MenuAnUserComponent implements OnInit {

  credential: Credentials;
  items: LidarMenu[] = [];

  constructor(
    private credentialService: CredentialService
  ) { }

  ngOnInit() {
    this.items = MENU;
    this.credential = this.credentialService.credentials;
  }

}
