import {Injectable, OnDestroy} from '@angular/core';
import {Entite} from '@model/entite';
import {BehaviorSubject} from 'rxjs';
import {CredentialService} from './credential.service';
import {Agence} from '@model/agence';
import {AgenceCorporateModule} from '@model/agence-corporate-module';
import {EntiteCorporateModule} from '@model/entite-corporate-module';
import {NGXLogger} from 'ngx-logger';

@Injectable({
  providedIn: 'root'
})
export class CorporateModuleService implements OnDestroy {

  protected state = new BehaviorSubject(null);
  protected state$ = this.state.asObservable();

  constructor(
    public credentials: CredentialService,
    private logger: NGXLogger,
  ) {
  }

  ngOnDestroy() {
  }

  public getState() {
    return this.state$;
  }

  getData() {
    if (this.credentials.isEntite()) {
      this.state.next(this.credentials.credentials.user.entite_user.entite);
    }

    if (this.credentials.isAgence()) {
      this.state.next(this.credentials.credentials.user.agence_user.agence);
    }
  }

  can(entity: Agence | Entite, ids: number[]): boolean {
    let b = false;

    this.logger.debug(entity);

    if (entity instanceof Agence) {
      if (this.credentials.isAgence()) {
        entity.agence_corporate_modules.forEach((acm: AgenceCorporateModule) => {
          ids.forEach((id: number) => {
            if (acm.corporate_module.id === id) {
              b = true;
            }
          });
        });
      }
    } else if (entity instanceof Entite) {
      if (this.credentials.isEntite()) {
        entity.entite_corporate_modules.forEach((acm: EntiteCorporateModule) => {
          ids.forEach((id: number) => {
            if (acm.corporate_module.id === id) {
              b = true;
            }
          });
        });
      }
    }

    return b;
  }

  canAgence(entity: Agence, ids: number[]): boolean {
    let b = false;

    if (this.credentials.isAgence()) {
      entity.agence_corporate_modules.forEach((acm: AgenceCorporateModule) => {
        ids.forEach((id: number) => {
          if (acm.corporate_module.id === id) {
            b = true;
          }
        });
      });
    }

    return b;
  }

  canEntite(entity: Entite, ids: number[]): boolean {
    let b = false;

    if (this.credentials.isEntite()) {
      entity.entite_corporate_modules.forEach((acm: EntiteCorporateModule) => {
        ids.forEach((id: number) => {
          if (acm.corporate_module.id === id) {
            b = true;
          }
        });
      });
    }

    return b;
  }

}
