import {Entite} from '@model/entite';
import {AgenceCarte} from '@model/agence-carte';
import {AgenceResponsable} from '@model/agence-responsable';
import {AgenceProfile} from '@model/agence-profile';
import {AgenceUser} from '@model/agence-user';
import {AgenceCorporateModule} from '@model/agence-corporate-module';
import { Beneficiaire } from '@model/beneficiaire';
import { UserInvite } from '@model/user-invite';

export class Agence {
  id: number;
  // tslint:disable-next-line:variable-name
  entite_id: number;
  code: string;
  name: string;
  alias: string;
  rccm: string;
  adresse: string;
  logo: string;
  statut: number;
  created: string;
  updated: string;
  entite: Entite;
  beneficiaires: Beneficiaire[];
  // tslint:disable-next-line:variable-name
  agence_carte: AgenceCarte;
  // tslint:disable-next-line:variable-name
  agence_responsable: AgenceResponsable;
  // tslint:disable-next-line:variable-name
  agence_users: AgenceUser[];
  // tslint:disable-next-line:variable-name
  agence_profiles: AgenceProfile[];
  // tslint:disable-next-line:variable-name
  agence_corporate_modules: AgenceCorporateModule[];
  // tslint:disable-next-line:variable-name
  user_invites: UserInvite[];
}
