import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Credentials, CredentialService } from '@core/credential.service';
import { Response } from '@model/response';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

export interface LoginContext {
  username: string;
  password: string;
  remember?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(
    private credentials: CredentialService,
    private http: HttpClient
  ) {
  }

  /**
   * Authenticates the user.
   * @param context The login parameters.
   * @return The user credentials.
   */
  login(context: LoginContext): Observable<Credentials> {
    return this.http
      .post<Credentials>('/users/login.json', context)
      .pipe(
        map(data => {
          if (data && data.token) {
            return data;
          }

          if (data instanceof HttpErrorResponse) {
            throw Error(data.message);
          }
          throw Error(JSON.parse(JSON.stringify(data)).errorMessage);
        })
      );
  }

  forgot(email: string, username: string): Observable<any> {
    return this.http.post(
      '/users/forgot.json',
      { name: email, username }
    );
  }

  /**
   * Logs out the user and clear credentials.
   * @return True if the user was logged out successfully.
   */
  logout(): Observable<boolean> {
    // Customize credentials invalidation here
    this.credentials.setCredentials();
    return of(true);
  }

  resolved(captcha: string): Observable<any> {
    return this.http
      .post(
        '/users/verifyCaptcha.json',
        { response: captcha }
      );
  }

  resolvedi(captcha: string): Observable<boolean> {
    return this.http
      .post<Response>(
        '/users/verifyCaptchai.json',
        { response: captcha }
      )
      .pipe(
        map(data => {
          return data.code === 0 && data.captchaResponse && data.captchaResponse.success === true;
        })
      );
  }
}
