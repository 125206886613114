<div class="limiter">
    <div class="container-login100">
        <div class="wrap-login100">
            <form
                [formGroup]="form"
                (ngSubmit)="login()"
                class="login100-form validate-form"
            >
                <div class="row mb-5">
                    <div class="col col-md-3">
                        <img
                            width="160"
                            src="assets/img/logo-lidar.png"
                        >
                    </div>
                </div>
                <div
                    class="wrap-input100"
                    [ngClass]="{'validate-input alert-validate': form.controls.username.touched && form.controls.username.errors}"
                    data-validate="Nom d'utilisateur est requis"
                >
                    <input
                        formControlName="username"
                        class="input100"
                        type="text"
                        name="username"
                        id="username"
                        required
                    >
                    <span class="label-input100">Nom d'utilisateur</span>
                </div>
                <div
                    class="wrap-input100"
                    [ngClass]="{'validate-input alert-validate': form.controls.password.touched && form.controls.password.errors}"
                    data-validate="Mot de passe est requis"
                >
                    <input
                        formControlName="password"
                        class="input100"
                        type="password"
                        name="password"
                        id="password"
                        required
                    >
                    <span class="label-input100">Mot de passe</span>
                </div>
                <div
                    class="wrap-input100"
                    style="border: none !important;"
                >
                    <re-captcha
                        name="recaptcha"
                        (resolved)="resolved($event)"
                    ></re-captcha>
                </div>
                <div class="container-login100-form-btn">
                    <button
                        [disabled]="!form.valid || isLoading"
                        class="login100-form-btn"
                    >
                        Accéder à mon compte
                    </button>
                </div>
            </form>
            <div class="login100-more">
                <div class="row p-5">
                    <div class="col col-md-9 mt-3">
                        <span style="font-weight: bold; font-size: 22px;">Pensée pour vous, vous en avez besoin</span>
                    </div>
                    <div class="col col-md-3">
                        <button
                            class="login100-form-btn text-uppercase"
                            style="font-weight: bold;"
                        >
                            Devenir client
                        </button>
                    </div>
                </div>
                <div class="row pl-5 pr-5 pb-2">
                    <div class="col-md-12">
                        <h3>
                            <i class="fa fa-question-circle-o mr-2"></i>
                            <a routerLink="/auth/forgot">Mot de passe oublié</a>
                        </h3>
                    </div>
                </div>
                <div class="row p-5">
                    <div class="col-md-12">
                        <h2>
                            <i class="fa fa-anchor mr-2"></i>
                            Conseils de sécurité
                        </h2>
                        <ul>
                            <li style="font-size: 18px;">
                                Vérifiez que l'adresse du site commence exactement par :
                                https://lidar.app; précédée par une icône cadenas et contient un 
                                https:// qui garantiront une connexion sécurisée.
                            </li>
                            <li style="font-size: 18px;">
                                Découvrez nos conseils sécurité et les bonnes pratiques pour consulter et identifier les dangers du web.
                            </li>
                        </ul>
                    </div>
                    <div class="col-md-12">
                        <h2>
                            <i class="fa fa-info-circle mr-2"></i>
                            Informations client
                        </h2>
                        <ul>
                            <li style="font-size: 18px;">
                                Si vous rencontrez des problèmes techniques lors de votre navigation,
                                nous vous invitons à contacter notre support  au +225 21 78 60 56
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>

<footer>
    <div class="row">
        <div class="col col-sm-2 p-5">
            <img
                width="120"
                src="assets/img/logo-lidar.png"
            >
        </div>
        <div class="col col-sm-3 p-5">
            <h4 style="color: white;">{{'Informations légales' | uppercase}}</h4>
            <ul>
                <li>Mentions légales</li>
                <li>Politique sécuritaire</li>
                <li>Politique de confidentialité</li>
                <li>Tarifs et conditions</li>
            </ul>
        </div>
        <div class="col col-sm-2 p-5">
            <h4 style="color: white;">{{'Aide' | uppercase}}</h4>
            <ul>
                <li>FAQs</li>
                <li>Fiches pratiques</li>
                <li>Nous contacter</li>
            </ul>
        </div>
        <div class="col col-sm-3 p-5">
            <h4 style="color: white;">{{'Trouver une agence' | uppercase}}</h4>
            <ul>
                <li>Retrouvez l'agence la plus proche</li>
            </ul>
        </div>
        <div class="col col-sm-2 p-5">
            <h4 style="color: white;">{{'Nous suivre' | uppercase}}</h4>

            <div class="footer-section_reseaux-sociaux-container mt-3">
                <a
                    href="javascript:;"
                    class="mr-2"
                >
                    <!-- <img src="/rsc/contrib/image/particuliers/homepage/fb.svg" alt="facebook"> -->
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="8"
                        height="19"
                        viewBox="0 0 8 19"
                    >
                        <g
                            fill="none"
                            fill-rule="evenodd"
                        >
                            <path
                                fill="#FFF"
                                d="M8 8.83H5.356V19H1.678V8.72H0V5.787h1.678c0-1.745-1-6.422 6.286-5.715v3.45c-1.179 0-2.642-.296-2.642 2.265H8v3.042z"
                            ></path>
                        </g>
                    </svg>
                </a>
                <a
                    href="javascript:;"
                    class="mr-2"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="23"
                        height="19"
                        viewBox="0 0 23 19"
                    >
                        <g
                            fill="none"
                            fill-rule="evenodd"
                        >
                            <path
                                fill="#FFF"
                                d="M7.396 19C3.95 19 .81 17.546 0 16.923c0 0 3.85.727 6.787-2.073-1.518-.518-3.342-.933-4.152-3.318.91.207.91.207 1.823-.103C2.937 10.704 1.621 9.562.81 6.763c.811.414 1.622.621 2.23.621C1.62 5.829.203 3.654 1.518.956c.914.831 4.967 5.288 9.731 4.873 0-2.28.708-4.25 2.329-5.184 1.621-.934 4.359-1.037 5.674.935 1.047.552 2.838.415 3.748 0-.405.83-1.824 2.696-2.532 3.213C20.568 5.622 19.86 19 7.396 19z"
                            ></path>
                        </g>
                    </svg>
                </a>
                <a
                    href="javascript:;"
                    class="mr-2"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="27"
                        height="19"
                        viewBox="0 0 27 19"
                    >
                        <g
                            fill="none"
                            fill-rule="evenodd"
                        >
                            <path
                                fill="#FFF"
                                d="M11.713 13.004l-.002-7.59 7.296 3.808-7.294 3.782zM26.73 4.099s-.264-1.861-1.073-2.682C24.63.342 23.479.336 22.952.273 19.173 0 13.506 0 13.506 0h-.012S7.827 0 4.048.273C3.521.336 2.37.342 1.343 1.417.533 2.237.27 4.099.27 4.099S0 6.285 0 8.47v2.049c0 2.187.27 4.372.27 4.372s.264 1.862 1.073 2.681C2.37 18.65 3.72 18.616 4.32 18.73c2.16.207 9.18.271 9.18.271s5.673-.008 9.452-.281c.527-.063 1.678-.07 2.705-1.146.81-.819 1.073-2.68 1.073-2.68s.27-2.186.27-4.373V8.471c0-2.186-.27-4.372-.27-4.372z"
                            ></path>
                        </g>
                    </svg>
                </a>
                <a
                    href="javascript:;"
                    class="mr-2"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        width="19"
                        height="19"
                        viewBox="0 0 19 19"
                    >
                        <defs>
                            <path
                                id="a"
                                d="M0 .006h18.994v18.992H0z"
                            ></path>
                        </defs>
                        <g
                            fill="none"
                            fill-rule="evenodd"
                        >
                            <g>
                                <mask
                                    id="b"
                                    fill="#fff"
                                >
                                    <use xlink:href="#a"></use>
                                </mask>
                                <path
                                    fill="#FFF"
                                    d="M9.497.006c-2.58 0-2.903.01-3.916.057-1.01.046-1.7.207-2.305.442A4.655 4.655 0 0 0 1.594 1.6 4.655 4.655 0 0 0 .499 3.282C.264 3.886.103 4.577.057 5.587.011 6.6 0 6.924 0 9.503c0 2.58.01 2.903.057 3.916.046 1.01.207 1.7.442 2.305a4.655 4.655 0 0 0 1.095 1.682 4.656 4.656 0 0 0 1.682 1.095c.604.235 1.295.396 2.305.442 1.013.046 1.337.057 3.916.057 2.58 0 2.903-.01 3.916-.057 1.01-.046 1.7-.207 2.305-.442a4.656 4.656 0 0 0 1.682-1.095 4.656 4.656 0 0 0 1.095-1.682c.235-.604.396-1.295.442-2.305.046-1.013.057-1.337.057-3.916 0-2.58-.01-2.903-.057-3.916-.046-1.01-.207-1.7-.442-2.305A4.656 4.656 0 0 0 17.4 1.6 4.655 4.655 0 0 0 15.718.505C15.114.27 14.423.109 13.413.063 12.4.017 12.076.006 9.497.006zm0 1.711c2.536 0 2.836.01 3.838.056.926.042 1.428.196 1.763.327.443.172.76.378 1.092.71.332.332.538.649.71 1.092.13.335.285.837.327 1.763.046 1.002.056 1.302.056 3.838s-.01 2.836-.056 3.838c-.042.926-.196 1.428-.327 1.763-.172.443-.378.76-.71 1.092a2.942 2.942 0 0 1-1.092.71c-.335.13-.837.285-1.763.327-1.002.046-1.302.056-3.838.056s-2.836-.01-3.838-.056c-.926-.042-1.428-.196-1.763-.327a2.942 2.942 0 0 1-1.092-.71 2.943 2.943 0 0 1-.71-1.092c-.13-.335-.285-.837-.327-1.763-.046-1.002-.056-1.302-.056-3.838s.01-2.836.056-3.838c.042-.926.196-1.428.327-1.763.172-.443.378-.76.71-1.092a2.942 2.942 0 0 1 1.092-.71c.335-.13.837-.285 1.763-.327 1.002-.046 1.302-.056 3.838-.056z"
                                    mask="url(#b)"
                                ></path>
                            </g>
                            <path
                                fill="#FFF"
                                d="M9.497 12.669a3.166 3.166 0 1 1 0-6.332 3.166 3.166 0 0 1 0 6.332zm0-8.043a4.877 4.877 0 1 0 0 9.754 4.877 4.877 0 0 0 0-9.754zM15.706 4.433a1.14 1.14 0 1 1-2.28 0 1.14 1.14 0 0 1 2.28 0"
                            ></path>
                        </g>
                    </svg>
                </a>
                <a
                    href="javascript:;"
                    class="mr-2"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="19"
                        viewBox="0 0 20 19"
                    >
                        <g
                            fill="none"
                            fill-rule="evenodd"
                        >
                            <path
                                fill="#FFFFFE"
                                d="M16.518 16.19h-2.873v-4.41c0-1.05-.02-2.403-1.494-2.403-1.496 0-1.726 1.145-1.726 2.328v4.486H7.553V7.123h2.757v1.239h.039c.384-.713 1.322-1.464 2.72-1.464 2.912 0 3.45 1.878 3.45 4.319v4.974zM4.312 5.885c-.921 0-1.667-.733-1.667-1.635 0-.901.746-1.633 1.667-1.633.92 0 1.667.732 1.667 1.633 0 .902-.747 1.635-1.667 1.635zM2.874 16.19h2.875V7.123H2.874v9.068zM17.95 0H1.43C.642 0 0 .613 0 1.37v16.26C0 18.385.641 19 1.43 19h16.52c.791 0 1.435-.614 1.435-1.37V1.37c0-.757-.644-1.37-1.435-1.37z"
                            ></path>
                        </g>
                    </svg>
                </a>
            </div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col col-md-12 text-center">
            <i class="fa fa-copyright mr-2"></i>
            Copyright 2020 - FA &amp; FA
        </div>
    </div>
</footer>
