<div class="all-wrapper">
  <div class="layout-w">
    <!-- ------------------
    START - Mobile Menu
    ------------------ -->
    <div class="menu-mobile menu-activated-on-click color-scheme-dark">
      <app-logo-buttons></app-logo-buttons>
      <app-menu-an-user></app-menu-an-user>
    </div>
    <!-- ------------------
    END - Mobile Menu
    ------------------ -->
    <!-- ------------------
    START - Main Menu
    ------------------ -->
    <div class="menu-w">
      <app-logo></app-logo>
      <app-logged-user></app-logged-user>
      <h1 class="menu-page-header">Page Header</h1>
      <app-main-menu></app-main-menu>
      <app-side-menu-magic></app-side-menu-magic>
    </div>
    <!-- ------------------
    END - Main Menu
    ------------------ -->
    <div class="content-w">
      <!-- ------------------
      START - Top Bar
      ------------------ -->
      <div class="top-bar">
        <div
          *ngIf="agence && corporate.canAgence(agence, [env.corporate.SD])"
          class="fancy-selector-w"
          #fancySelectorW
        >
          <div
            *ngIf="academicYear; else academicLoader"
            class="fancy-selector-current"
          >
            <div class="fs-img"><i class="icon-layers fa-2x"></i></div>
            <div class="fs-main-info">
              <div class="fs-name">
                <span style="font-weight: bold !important; color: #fff">{{
                  credential.user.agence_user.agence.name
                }}</span>
              </div>
              <div class="fs-sub">
                <span
                  >Année académique: {{ academicYear.academic_year.name }}</span
                >
              </div>
            </div>
            <div
              class="fs-selector-trigger"
              [routerLink]="['/admin/settings/annee-academique']"
            >
              <i class="os-icon os-icon-arrow-down4"></i>
            </div>
          </div>

          <ng-template #academicLoader>
            <p-progressSpinner
              [style]="{ width: '40px', height: '40px' }"
              [styleClass]="'text-center'"
              strokeWidth="3"
              animationDuration=".5s"
            ></p-progressSpinner>
          </ng-template>

          <div class="fancy-selector-options">
            <div class="fancy-selector-actions text-right">
              <a
                class="btn btn-primary"
                routerLink="/admin/settings/agences-annees-academiques/add"
              >
                <i class="os-icon os-icon-ui-22"></i>
                <span>Ajouter une année académique</span>
              </a>
            </div>
          </div>
        </div>

        <div *ngIf="credentialService.isEntite()" class="fancy-selector-w">
          <div class="fancy-selector-current">
            <div class="fs-img"><i class="icon-layers fa-2x"></i></div>
            <div class="fs-main-info">
              <div class="fs-name">
                <span style="color: #fff">{{
                  credential.user.entite_user.entite.name
                }}</span>
              </div>
              <!--<div class="fs-sub"><span>{{academicYear.start_at | date:'dd/MM/yyyy'}}
                - {{academicYear.end_at | date:'dd/MM/yyyy'}}</span>
              </div>-->
            </div>
          </div>
        </div>

        <!-- ------------------
        START - Top Menu Controls
        ------------------ -->
        <div class="top-menu-controls">
          <!-- ------------------
          START - User avatar and menu in secondary top menu
          ------------------ -->
          <div class="logged-user-w">
            <div class="logged-user-i">
              <div class="avatar-w">
                <img alt src="assets/img/avatar.png" />
              </div>
              <div class="logged-user-menu color-style-bright">
                <div class="logged-user-avatar-info">
                  <div class="avatar-w">
                    <img alt src="assets/img/avatar.png" />
                  </div>
                  <div class="logged-user-info-w">
                    <div class="logged-user-name">
                      {{ credential.user.nom | uppercase }}
                      {{ credential.user.prenoms }}
                    </div>
                    <div class="logged-user-role">
                      {{ credential.user.profile.name }}
                    </div>
                  </div>
                </div>

                <div class="bg-icon">
                  <i class="os-icon os-icon-wallet-loaded"></i>
                </div>
                <ul>
                  <li *canAdminAccess="true">
                    <a routerLink="/admin/profiles">
                      <i class="os-icon os-icon-users"></i>
                      <span>Mes profils</span>
                    </a>
                  </li>
                  <li *canAdminAccess="true">
                    <a routerLink="/admin/utilisateurs">
                      <i class="os-icon os-icon-user"></i>
                      <span>Mes utilisateurs</span>
                    </a>
                  </li>
                  <ng-container *canProfileAccess="['MULTI']">
                    <li *canMasterAccess="true">
                      <a routerLink="/admin/settings">
                        <i class="os-icon os-icon-ui-46"></i>
                        <span>Paramètres</span>
                      </a>
                    </li>
                  </ng-container>
                  <li>
                    <a routerLink="/auth/login">
                      <i class="os-icon os-icon-signs-11"></i>
                      <span>Me déconnecter</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <!-- ------------------
          END - User avatar and menu in secondary top menu
          ------------------ -->
        </div>
        <!-- ------------------
        END - Top Menu Controls
        ------------------ -->
      </div>
      <!-- ------------------
      END - Top Bar
      ------------------ -->
      <!-- ------------------
          START - Breadcrumbs
          ------------------ -->
      <ul *ngIf="annonces$ | async as annonces" class="breadcrumb">
        <li class="breadcrumb-item"><span>Annonces</span></li>
        <li hidden class="breadcrumb-item marquee">
          <span *ngFor="let annonce of annonces"
            ><strong>[{{ annonce.name }}]</strong> {{ annonce.message }}</span
          >
        </li>
      </ul>

      <!-- ------------------
      END - Breadcrumbs
      ------------------ -->

      <div class="content-panel-toggler">
        <i class="os-icon os-icon-grid-squares-22"></i>
        <span>Sidebar</span>
      </div>

      <ng-container *canProfileAccess="[Profiles.multi]">
        <ng-container *canActionAccess="[WfRole.canOraOp]">
          <div *ngIf="orabankOperations.length > 0" class="content-i">
            <div class="content-box">
              <div class="element-wrapper pb-0">
                <div class="element-box-tp">
                  <div class="element-box-content">
                    <div class="alert alert-warning" role="alert">
                      <strong>Alerte !</strong>
                      De nouvelles opérations sont disponibles.
                      <p-button
                        (onClick)="goOrabankOp()"
                        type="button"
                        styleClass="ui-button-raised ui-button-primary smaller"
                        icon="os-icon os-icon-refresh-cw"
                        label="Accéder maintenant"
                      ></p-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>

      <router-outlet></router-outlet>
    </div>
  </div>
  <div class="display-type"></div>
</div>
