import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CredentialService } from '@core/credential.service';
import { AbstractService } from '@core/api';
import { Response } from '@model/response';
import { ShellService } from '@core/shell.service';
import { Agence } from '@model/agence';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AgenceService extends AbstractService {

  constructor(
    protected credentialService: CredentialService,
    protected shellService: ShellService,
    protected http: HttpClient
  ) {
    super();
  }

  index(params?: HttpParams): Observable<Agence[]> {
    this.endpoint = `/agences.json`;

    if (this.credentialService.isEntite()) {
      this.endpoint = `/entites/${this.credentialService.credentials.user.entite_user.entite.id}` + this.endpoint;
    }

    return this.http
      .get<Response>(this.endpoint, { params })
      .pipe(
        map((response: Response) => {
          if (response.code === 0) {
            return response.agences;
          }
          throw new Error(response.errorMessage);
        })
      );
  }

  all(parameters: any): Observable<any> {
    this.endpoint = `/agences/all.json`;

    if (this.credentialService.isEntite()) {
      this.endpoint = `/entites/${this.credentialService.credentials.user.entite_user.entite.id}` + this.endpoint;
    }

    return this.http
      .post<any>(this.endpoint, parameters);
  }

  create(data: Agence): Observable<Response> {
    this.endpoint = `/agences.json`;

    if (this.credentialService.isEntite()) {
      this.endpoint = `/entites/${this.credentialService.credentials.user.entite_user.entite.id}` + this.endpoint;
    }

    return this.http
      .post<Response>(this.endpoint, data);
  }

  read(id: number): Observable<Response> {
    this.endpoint = `/agences/${id}.json`;

    if (this.credentialService.isEntite()) {
      this.endpoint = `/entites/${this.credentialService.credentials.user.entite_user.entite.id}` + this.endpoint;
    }

    if (this.credentialService.isAgence()) {
      this.endpoint = `/agences/${this.credentialService.credentials.user.agence_user.agence.id}` + this.endpoint;
    }

    return this.http
      .get<Response>(this.endpoint);
  }

  update(id: number, data: Agence): Observable<Response> {
    this.endpoint = `/agences/${id}.json`;

    if (this.credentialService.isEntite()) {
      this.endpoint = `/entites/${this.credentialService.credentials.user.entite_user.entite.id}` + this.endpoint;
    }

    return this.http
      .put<Response>(this.endpoint, data);
  }

  delete(id: number): Observable<Response> {
    this.endpoint = `/agences/${id}.json`;

    if (this.credentialService.isEntite()) {
      this.endpoint = `/entites/${this.credentialService.credentials.user.entite_user.entite.id}` + this.endpoint;
    }

    return this.http
      .delete<Response>(this.endpoint);
  }

}
