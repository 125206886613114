import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthorizationService } from './authorization.service';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[canMasterAccess]'
})
export class CanMasterAccessDirective implements OnInit {

  @Input('canMasterAccess') required: boolean;

  constructor(
    private authorization: AuthorizationService,
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<any>,
  ) { }

  ngOnInit(): void {
    this.apply();
  }

  private apply(): void {
    if (this.required && this.authorization.isMaster()) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

}
