import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AgenceService } from '@app/admin/agences/shared/services/agence.service';
import { CredentialService } from '@core/credential.service';
import { LoggerService } from '@core/logger.service';
import { Profiles } from '@core/profiles.enum';
import { OperationRoles } from '@core/roles.enum';
import { untilDestroyed } from '@core/until-destroyed';
import { Agence } from '@interface/agence';
import { Filiere } from '@interface/filiere';
import { AgenceAcademicYearStore } from '@lidar/stores/agence-academic-year.store';
import { MessageService } from 'primeng/api';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthorizationService } from 'src/@autorization/authorization.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, OnDestroy {

  Profile = Profiles;
  OperationRole = OperationRoles;
  listOfAgences: Agence[];
  agences$: Observable<Agence[]>;
  selectedAgence: Agence;
  form: FormGroup;
  agenceAcademicYearId: number;

  constructor(
    private agenceAcademicYearStore: AgenceAcademicYearStore,
    private http: HttpClient,
    private messageService: MessageService,
    private authorization: AuthorizationService,
    public credentialService: CredentialService,
    public agenceService: AgenceService,
    private logger: LoggerService,
    private router: Router,
    private fb: FormBuilder,
  ) {
  }

  ngOnInit() {
    this.form = this.fb.group({
      agence_id: [0]
    });

    // Subscribe to store
    /*    this.agenceAcademicYearStore.state$
          .pipe(
            untilDestroyed(this),
            tap((next) => {
              if (next.active) {
                this.agenceAcademicYearId = next.active.id;


              }
            })
          )
          .subscribe();*/
    if (this.credentialService.isEntite()) {
      this.agences$ = this
        .getAgences()
        .pipe(
          untilDestroyed(this),
          tap((agences: Agence[]) => {
            this.listOfAgences = agences;
          })
        );
    }
  }

  ngOnDestroy(): void {
  }

  onChangeAgence() {
    this.selectedAgence = null;
    this.listOfAgences.forEach(agence => {
      if (agence.id === Number(this.form.get('agence_id').value)) {
        this.selectedAgence = agence;
      }
    });
  }

  getAgences(): Observable<Agence[]> {
    const params: HttpParams = new HttpParams()/*
      .set('agence_academic_year_id', String(this.agenceAcademicYearId))*/;

    return this.agenceService
      .index(params);
  }

  getPaymentReceived(): number {
    let sumOf = 0;
    if (this.listOfAgences) {
      if (Number(this.form.get('agence_id').value) === 0) {
        this.listOfAgences.forEach(agence => {
          agence.filieres.forEach(filiere => {
            ['inscription', 'scolarite', 'autre'].forEach(type => {
              sumOf += this.getTotal(filiere, type);
            });
          });
        });
      } else {
        this.listOfAgences.forEach(agence => {
          if (Number(this.form.get('agence_id').value) === agence.id) {
            agence.filieres.forEach(filiere => {
              ['inscription', 'scolarite', 'autre'].forEach(type => {
                sumOf += this.getTotal(filiere, type);
              });
            });
          }
        });
      }
    }

    return sumOf;
  }

  getTotal(filiere: Filiere, type: string): number {
    let montant = 0;
    filiere.scolarites.forEach(scolarite => {
      scolarite.scolarite_fees.forEach(scolariteFee => {
        if (scolariteFee.type === type) {
          scolariteFee.scolarite_payments.forEach(payment => {
            montant += payment.montant;
          });
        }
      });
    });

    return montant;
  }

  countRegistration(agence: Agence): number {
    let size = 0;
    agence.filieres.forEach(filiere => {
      filiere.classrooms.forEach(classroom => {
        size += classroom.registrations.length;
      });
    });

    return size;
  }

  getTotalInscrits(): number {
    let size = 0;
    if (this.listOfAgences) {
      this.listOfAgences.forEach(agence => {
        if (Number(this.form.get('agence_id').value) === 0) {
          size += this.countRegistration(agence);
        } else {
          if (Number(this.form.get('agence_id').value) === agence.id) {
            size += this.countRegistration(agence);
          }
        }
      });
    }

    return size;
  }
}
