<div class="auth-box-w">
    <div class="logo-w">
        <a routerLink="/">
            <img
                width="200"
                src="assets/img/logo-lidar.png"
            >
        </a>
    </div>
    <h4 class="auth-header">Page de connexion</h4>
    <form
        [formGroup]="form"
        (ngSubmit)="login()"
    >
        <div
            class="form-group"
            [ngClass]="{'has-error has-danger': form.controls.username.touched && form.controls.username.errors}"
        >
            <label
                for="username"
                translate="yes"
                i18n
            >
                Nom d'utilisateur
            </label>
            <input
                pInputText
                formControlName="username"
                [readOnly]="isLoading"
                class="form-control"
                id="username"
                name="username"
                placeholder="Saisir votre nom d'utilisateur"
                required
            >
            <div class="pre-icon os-icon os-icon-user-male-circle"></div>
            <div
                class="help-block form-text with-errors form-control-feedback"
                *ngIf="form.controls.username.touched && form.controls.username.errors"
            >
                <ul class="list-unstyled">
                    <li *ngIf="form.controls.username.errors.required">Champs obligatoire.</li>
                </ul>
            </div>
        </div>
        <div
            class="form-group"
            [ngClass]="{'has-error has-danger': form.controls.password.touched && form.controls.password.errors}"
        >
            <label
                for="password"
                translate="yes"
                i18n
            >
                Mot de passe
            </label>
            <input
                pInputText
                formControlName="password"
                [readOnly]="isLoading"
                class="form-control"
                id="password"
                name="password"
                placeholder="Saisir votre mot de passe"
                type="password"
                required
            >
            <div class="pre-icon os-icon os-icon-fingerprint"></div>
            <div
                class="help-block form-text with-errors form-control-feedback"
                *ngIf="form.controls.password.touched && form.controls.password.errors"
            >
                <ul class="list-unstyled">
                    <li *ngIf="form.controls.password.errors.required">Champs obligatoire.</li>
                </ul>
            </div>
        </div>
        <div class="form-group">
            <re-captcha
                name="recaptcha"
                (resolved)="resolved($event)"
            ></re-captcha>
        </div>
        <div class="buttons-w">
            <p-button
                [disabled]="!form.valid || isLoading"
                styleClass="ui-button-raised ui-button-primary"
                label="Me connecter"
                type="submit"
            ></p-button>
        </div>

        <div class="buttons-w mt-3 text-center">
            <button
                type="button"
                class="btn btn-link"
                (click)="forgot()"
            >
                Mot de passe oublié ?
            </button>
        </div>
    </form>
</div>
