import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { ListboxModule } from 'primeng/listbox';
import { PasswordModule } from 'primeng/password';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

import { UserFormComponent } from '.';
import { ProfileFormComponent } from './profile-form/profile-form.component';

const FORMS = [
  UserFormComponent,
  ProfileFormComponent
];

@NgModule({
  declarations: [
    ...FORMS
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,

    CheckboxModule,
    ListboxModule,
    ProgressSpinnerModule,
    ButtonModule,
    PasswordModule,
  ],
  exports: [
    ...FORMS
  ]
})
export class FormModule { }
