import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { C_SITE_KEY } from '@app/app.module';
import { AuthenticationService, LoginContext } from '@core/authentication.service';
import { CredentialService } from '@core/credential.service';
import { ShellService } from '@core/shell.service';
import { untilDestroyed } from '@core/until-destroyed';
import { environment } from '@env/environment';
import { RecaptchaComponent } from 'ng-recaptcha';
import { NGXLogger } from 'ngx-logger';
import { MessageService } from 'primeng/api';
import { of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import Swal from 'sweetalert2';

class Login implements LoginContext {
  username: string;
  password: string;
  remember: boolean;
}

@Component({
  selector: 'app-signinv2',
  templateUrl: './signinv2.component.html',
  styleUrls: ['./signinv2.component.css']
})
export class Signinv2Component implements OnInit, OnDestroy {
  @ViewChild('captchaRef', { static: true }) reCaptcha: RecaptchaComponent;

  form: FormGroup;
  data: Login;
  error: any | undefined;
  env = environment;
  loading: boolean;

  SITE_KEY = C_SITE_KEY;

  swalWithBootstrapButtons = Swal.mixin({});

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private logger: NGXLogger,
    private credentials: CredentialService,
    private shellService: ShellService,
    private auth: AuthenticationService,
    private messageService: MessageService,
  ) {
  }

  login(event: string) {
    if (this.form.valid) {
      this.swalWithBootstrapButtons.fire({
        title: 'Tentative de connexion!',
        html: 'Patientez un instant...',
        timerProgressBar: true,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
        onOpen: () => this.env.allowGooglePlugins ? this.processCaptcha(event) : this.process(),
        allowOutsideClick: () => !Swal.isLoading(),
      }).then();
    }
  }

  ngOnInit() {
    this.loading = false;
    this.data = new Login();

    if (this.credentials.isAuthenticated()) {
      this.auth.logout().pipe(
        map(ret => {
          if (ret) {
            this.router.navigate(['/auth/login']).then();
          }
        })
      );
    }

    this.form = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  ngOnDestroy(): void {
  }

  forgot() {
    this.router.navigate(['/auth/forgot']).then();
  }

  become() {
    window.open('https://lidar.ci/commandez-votre-carte-lidar', '_blank');
  }

  processCaptcha(captcha: string) {
    this.loading = true;
    this.form.disable();

    if (captcha !== null) {
      this.auth.resolvedi(captcha)
        .pipe(
          untilDestroyed(this),
          map((response: boolean) => {
            if (response) {
              this.process();
            }
          }),
          catchError(error => {
            if (this.swalWithBootstrapButtons.isLoading()) {
              this.swalWithBootstrapButtons.close();
            }

            this.reCaptcha.reset();
            this.loading = false;
            this.form.enable();

            this.messageService.add({
              severity: 'error',
              summary: (error instanceof HttpErrorResponse) ? error.statusText : 'Erreur!',
              detail: (error instanceof HttpErrorResponse) ? error.message : error,
              closable: true,
              sticky: true
            });
            return of(error);
          }),
        )
        .subscribe();
    } else {
      this.process();
    }
  }

  process() {
    this.auth.login(this.form.value)
      .pipe(
        untilDestroyed(this),
        tap(response => {
          this.credentials.setCredentials(response, this.data.remember);
          window.location.replace('/');
        }),
        catchError(error => {
          if (this.swalWithBootstrapButtons.isLoading()) {
            this.swalWithBootstrapButtons.close();
          }

          $('body').removeAttr('style');
          $('body').removeProp('style');

          this.reCaptcha.reset();
          this.loading = false;
          this.form.enable();

          this.messageService.add({
            severity: 'error',
            summary: (error instanceof HttpErrorResponse) ? error.statusText : 'Erreur!',
            detail: (error instanceof HttpErrorResponse) ? error.message : error
          });
          return of(error);
        })
      )
      .subscribe();
  }

}
