<div class="side-menu-magic">
    <img
        width=100
        src="assets/img/logo3.png"
    >
    <p>{{version.appVersion}}</p>
    <div class="btn-w">
        <a
            class="btn btn-success btn-rounded"
            href="javascript:;"
            target="_blank"
        >
            Aide
        </a>
    </div>
</div>
