import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import * as $ from 'jquery';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})
export class AuthComponent implements OnInit, AfterViewInit, OnDestroy {

  wrapper: any;
  body: any;

  constructor() { }

  ngOnInit() {
    this.body = document.querySelector('body');
    this.wrapper = document.querySelector('div.all-wrapper');
  }

  ngAfterViewInit(): void {
    //$(this.body).addClass('auth-wrapper');
    //$(this.wrapper).addClass('menu-side with-pattern');
  }

  ngOnDestroy(): void {
    //$(this.wrapper).removeClass('menu-side with-pattern');
    //$(this.body).removeClass('auth-wrapper');
  }

}
