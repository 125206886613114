import { CredentialService } from '@core/credential.service';
import { Injectable } from "@angular/core";
import { AyStore } from "./ay-store";

@Injectable({
    providedIn: 'root'
})
export class AnneeAcademiqueActive extends AyStore<number> {

    constructor(service: CredentialService) {
        super(service.credentials.user.user_agence_academic_year);
    }

    public set add(data: number) {
        this.state = data;
    }

}