import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
// @ts-ignore
import { AuthorizationService } from '@authorization/authorization.service';
import { LidarMenu } from '@core/api';
import { CorporateModuleService } from '@core/corporate-module.service';
import { Credentials, CredentialService } from '@core/credential.service';
import { Profiles } from '@core/profiles.enum';
import {
  AccepteurRoles,
  AgenceRoles,
  BeneficiaireRoles,
  BudgetPrevisionnelRoles,
  CommissionRoles,
  DelegueRoles,
  DistributeurRoles,
  EntrepriseRoles,
  FournisseurAgenceRoles,
  FournisseurRoles,
  OperationRoles,
  ProducteurRoles,
  ReportingRoles,
  SalarieRoles,
  StockRoles,
  TypeCarteRoles,
  WfRoles
} from '@core/roles.enum';
import { environment } from '@env/environment';
import { Agence } from '@model/agence';
import { Entite } from '@model/entite';

export const MENU: LidarMenu[] = [
  {
    label: 'Mon établissement',
    items: [
      {
        label: 'Inscriptions',
        link: '/admin/inscriptions',
        icon: 'icon-basket-loaded',
        profilesAccess: [Profiles.agence],
      },
      {
        label: 'Personnel',
        icon: 'os-icon os-icon-users',
        items: [
          {
            label: 'Enseignants',
            link: '/admin/enrolments/teachers',
            profilesAccess: [Profiles.agence],
          },
        ],
      },
      {
        label: 'Classes',
        link: '/admin/classrooms',
        icon: 'os-icon os-icon-home-34',
        profilesAccess: [Profiles.agence],
      },
      {
        label: 'Emplois du temps',
        link: '/admin/emplois-temps',
        icon: 'os-icon os-icon-calendar-time',
        profilesAccess: [Profiles.agence],
      },
      {
        label: 'Présences',
        icon: 'os-icon os-icon-sunrise',
        items: [
          // { label: 'Elèves', link: '/admin/presences/students' },
          {
            label: 'Enseignants',
            link: '/admin/presences/teachers',
            profilesAccess: [Profiles.agence],
          },
          {
            label: 'Elèves & Etudiants',
            link: '/admin/presences/students',
            profilesAccess: [Profiles.agence],
          },
        ],
      },
      {
        label: 'Résultats',
        icon: 'os-icon os-icon-agenda-1',
        items: [
          {
            label: 'Notes',
            link: '/admin/resultats/notes',
            profilesAccess: [Profiles.agence],
          },
          {
            label: 'Moyennes',
            link: '/admin/resultats/moyennes',
            profilesAccess: [Profiles.agence],
          },
        ],
      },
    ],
  },
  {
    label: 'Opérations',
    items: [
      {
        label: 'Historiques',
        link: '/admin/operations',
        icon: 'os-icon os-icon-clock',
      },
      {
        label: 'Paiement scolarités',
        link: '/admin/scolarites/paiements',
        icon: 'os-icon os-icon-wallet-loaded',
      },
    ],
  },
  {
    label: 'Reporting',
    items: [
      {
        label: 'Reporting',
        link: '/admin/reporting',
        icon: 'os-icon os-icon-bar-chart-stats-up',
        profilesAccess: [Profiles.agence],
      },
    ],
  },
];

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.css'],
})
export class MainMenuComponent implements OnInit, OnDestroy {
  env = environment;
  entite: Entite;
  agence: Agence;

  Profiles = Profiles;

  WfRole = WfRoles;
  FournisseurRole = FournisseurRoles;
  FournisseurAgenceRole = FournisseurAgenceRoles;
  ReportingRole = ReportingRoles;
  EntrepriseRole = EntrepriseRoles;
  AgenceRole = AgenceRoles;
  StockRole = StockRoles;
  TypeCarteRole = TypeCarteRoles;
  BeneficiaireRole = BeneficiaireRoles;
  SalarieRole = SalarieRoles;
  DistributeurRole = DistributeurRoles;
  AccepteurRole = AccepteurRoles;
  OperationRole = OperationRoles;
  CommissionRole = CommissionRoles;
  DelegueRole = DelegueRoles;
  ProducteurRole = ProducteurRoles;
  BudgetPrevisionnelRole = BudgetPrevisionnelRoles;
  credential: Credentials;

  items: LidarMenu[] = [];

  constructor(
    public credentialService: CredentialService,
    public authorization: AuthorizationService,
    public corporate: CorporateModuleService,
    public http: HttpClient
  ) { }

  ngOnInit() {
    this.items = MENU;

    this.credential = this.credentialService.credentials;

    this.corporate.getData();
    this.corporate.getState().subscribe((data) => {
      if (this.credentialService.isEntite()) {
        this.entite = data;
      }

      if (this.credentialService.isAgence()) {
        this.agence = data;
      }
    });
  }

  ngOnDestroy() { }
}
