<div class="content-i">

  <div class="content-box">

    <div *ngIf="credentialService.isMulti() || credentialService.isAgence()" class="element-wrapper">
      <div class="element-actions">
        <form class="form-inline justify-content-sm-end"><select class="form-control form-control-sm">
          <option value="Pending">Aujourd'hui</option>
        </select></form>
      </div>
      <h6 class="element-header">Panneau de contrôle</h6>
      <div class="element-content">
        <div class="row">
          <div *ngIf="credentialService.isAgence()" class="col-sm-4 col-xxxl-3">
            <a class="element-box el-tablo" href="#">
              <div class="label" style="color: #000; size: 16px !important; font-weight: bold">Code Agence</div>
              <div class="value">{{credentialService.credentials.user.agence_user.agence.code}}</div>
            </a>
          </div>
        </div>
      </div>
    </div>

    <ng-container *ngIf="credentialService.isEntite()">

      <div class="element-wrapper">
        <div class="element-actions">
          <form [formGroup]="form" class="form-inline justify-content-sm-end" (change)="onChangeAgence()" novalidate>
            <select *ngIf="agences$ | async as agences" formControlName="agence_id"
                    class="form-control form-control-sm">
              <option value=0>(tous les établissements)</option>
              <option *ngFor="let agence of agences" [value]=agence.id>{{agence.name | uppercase}}</option>
            </select>
          </form>
        </div>
        <h6 class="element-header">Panneau de contrôle</h6>
        <div class="element-content">
          <div class="row">

            <div class="col-sm-4 col-xxxl-4 red">
              <a class="element-box el-tablo centered smaller" href="#" style="background-color: #0d509a; color: #fff;">
                <div class="label" style="color: #fff; size: 16px !important; font-weight: bold">Total Inscriptions</div>
                <div class="value">{{getTotalInscrits() | number}}</div>
              </a>
            </div>

            <div class="col-sm-4 col-xxxl-4">
              <a class="element-box el-tablo centered smaller" href="#" style="background-color: #cc0000; color: #fff;">
                <div class="label" style="color: #fff; size: 16px !important; font-weight: bold">Paiements total reçus</div>
                <div class="value">{{getPaymentReceived() | currency:'XOF':'':'':'fr'}}</div>
              </a>
            </div>

            <div hidden class="col-sm-4 col-xxxl-4">
              <a class="element-box el-tablo" href="#">
                <div class="label">Volume horaire</div>
                <div class="value">{{'12:45'}}</div>
              </a>
            </div>

          </div>
        </div>
      </div>

      <div *ngIf="form.get('agence_id').value > 0" class="row pt-4">
        <div class="col-sm-12">
          <div class="element-wrapper"><h6 class="element-header">INSCRIPTIONS</h6>
            <div class="element-box-tp">
              <div class="table-responsive">

                <table *ngIf="selectedAgence" class="table table-padded">
                  <thead>
                  <tr>
                    <th></th>
                    <th>Filière</th>
                    <th>Niveau d'étude</th>
                    <th class="text-right">TOTAL</th>
                    <th class="text-right">TOTAL FRAIS D'INSCRIPTION</th>
                    <th class="text-right">TOTAL FRAIS DE SCOLARIT&Eacute;</th>
                    <th class="text-right">TOTAL FRAIS ANNEXES</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let filiere of selectedAgence.filieres">
                    <td class="text-center"><input class="form-control" type="checkbox"></td>
                    <td><span>{{filiere.name | uppercase}}</span></td>
                    <td>
                      <div class="smaller">{{filiere.niveau_etude.name | uppercase}}</div>
                    </td>
                    <td
                      class="text-right">{{(getTotal(filiere, 'inscription') + getTotal(filiere, 'scolarite') + getTotal(filiere, 'autre')) | currency:'XOF':'':'':'fr'}}</td>
                    <td class="text-right">{{getTotal(filiere, 'inscription') | currency:'XOF':'':'':'fr'}}</td>
                    <td class="text-right">{{getTotal(filiere, 'scolarite') | currency:'XOF':'':'':'fr'}}</td>
                    <td class="text-right">{{getTotal(filiere, 'autre') | currency:'XOF':'':'':'fr'}}</td>
                  </tr>
                  </tbody>
                </table>

              </div>
            </div>
          </div>
        </div>
      </div>

    </ng-container>


  </div>

</div>
