<div class="logged-user-w avatar-inline">
  <div class="logged-user-i">

    <div class="avatar-w">
      <img alt src="assets/img/avatar.png">
    </div>
    <div class="logged-user-info-w">
      <div class="logged-user-name">
        {{credential.user.nom | uppercase}} {{credential.user.prenoms}}
      </div>
      <div class="logged-user-role">{{credential.user.profile.name}}</div>
    </div>

    <div class="logged-user-toggler-arrow">
      <div class="os-icon os-icon-chevron-down"></div>
    </div>

    <div class="logged-user-menu color-style-bright">
      <div class="logged-user-avatar-info">
        <div class="avatar-w">
          <img alt src="assets/img/avatar.png">
        </div>
        <div class="logged-user-info-w">
          <div class="logged-user-name">
            {{credential.user.nom | uppercase}} {{credential.user.prenoms}}
          </div>
          <div class="logged-user-role">{{credential.user.profile.name}}</div>
        </div>
      </div>

      <div class="bg-icon">
        <i class="os-icon os-icon-wallet-loaded"></i>
      </div>

      <ul>
        <li>
          <a routerLink="/admin/myaccount">
            <i class="os-icon os-icon-star"></i>
            <span>Mon compte</span>
          </a>
        </li>
        <ng-container *canAdminAccess="true">
          <li>
            <a routerLink="/admin/profiles">
              <i class="os-icon os-icon-users"></i>
              <span>Mes profils</span>
            </a>
          </li>
        </ng-container>
        <ng-container *canAdminAccess="true">
          <li>
            <a routerLink="/admin/utilisateurs">
              <i class="os-icon os-icon-user"></i>
              <span>Mes utilisateurs</span>
            </a>
          </li>
        </ng-container>
        <li>
          <a routerLink="/admin/settings">
            <i class="os-icon os-icon-ui-46"></i>
            <span>Paramètres</span>
          </a>
        </li>
        <li>
          <a routerLink="/auth/login">
            <i class="os-icon os-icon-signs-11"></i>
            <span>Me déconnecter</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>
