import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService, LoginContext } from '@core/authentication.service';
import { CredentialService } from '@core/credential.service';
import { LoggerService } from '@core/logger.service';
import { ShellService } from '@core/shell.service';
import { untilDestroyed } from '@core/until-destroyed';
import { MessageService } from 'primeng/api';
import { map, shareReplay } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { HttpClient } from '@angular/common/http';

class Login implements LoginContext {
  username: string;
  password: string;
  remember: boolean;
}

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css']
})
export class SigninComponent implements OnInit, OnDestroy {

  form: FormGroup;
  data: Login;
  captcha: string;
  isLoading = false;
  error: any | undefined;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private http: HttpClient,
    private route: ActivatedRoute,
    private logger: LoggerService,
    private messageService: MessageService,
    private credentials: CredentialService,
    private shellService: ShellService,
    private auth: AuthenticationService
  ) { }

  login() {
    if (!this.captcha) {
      this.shellService.show('error', 'Erreur', 'Veuillez cocher la case "Je ne suis pas un robot" avant de poursuivre.');
    } else {
      this.isLoading = true;

      if (this.form.valid) {

        const swalWithBootstrapButtons = Swal.mixin({});
        swalWithBootstrapButtons.fire({
          title: 'Tentative de connexion!',
          html: 'Patientez un instant...',
          timerProgressBar: true,
          onBeforeOpen: () => {
            Swal.showLoading();
            this.isLoading = true;
          },
          onOpen: () => {

            this.auth.resolved(this.captcha)
              .pipe(
                untilDestroyed(this)
              )
              .subscribe(next => {
                const json = JSON.parse(JSON.stringify(next));

                if (json.code === 0 && json.captchaResponse.success) {

                  this.auth.login(this.form.value)
                    .pipe(
                      untilDestroyed(this),
                      shareReplay(),
                    )
                    .subscribe(credentials => {

                      if (credentials && credentials.token) {

                        this.credentials.setCredentials(credentials, this.data.remember);

                        swalWithBootstrapButtons.fire(
                          `Connexion réussie`,
                          'Bienvenue!',
                          'success'
                        ).then(e => {
                          this.router.navigate([this.route.snapshot.queryParams.redirect || '/'], { replaceUrl: true }).then();
                        });

                      } else {

                        $('body').removeAttr('style');
                        $('body').removeProp('style');
                        this.captcha = null;
                        this.isLoading = false;
                        this.logger.error(JSON.parse(JSON.stringify(credentials)).errorMessage);
                        // this.shellService.show('error', 'Désolé!', JSON.parse(JSON.stringify(credentials)).errorMessage, true);

                        swalWithBootstrapButtons.fire(
                          `Erreur`,
                          JSON.parse(JSON.stringify(credentials)).errorMessage,
                          'error'
                        );

                      }

                    }, error => {

                      $('body').removeAttr('style');
                      $('body').removeProp('style');
                      this.error = error;
                      // this.shellService.show('error', error.statusText, error.message);
                      // this.captcha = null;
                      this.isLoading = false;

                      swalWithBootstrapButtons.fire(
                        error.statusText,
                        error.message,
                        'error'
                      );

                    }, () => {

                      // this.captcha = null;
                      this.isLoading = false;

                    });

                } else {
                  this.captcha = null;
                  this.isLoading = true;
                  swalWithBootstrapButtons.fire(
                    `Erreur`,
                    'Code captcha erroné',
                    'error'
                  );
                }

              }, () => {
                // this.captcha = null;
                this.isLoading = true;
              });

          },
          allowOutsideClick: () => !Swal.isLoading(),
        }).then(_ => {
          this.isLoading = false;
        });

      }

    }
  }

  ngOnInit() {
    if (this.credentials.isAuthenticated()) {
      this.auth.logout().pipe(
        map(ret => {
          if (ret) {
            this.router.navigate(['/auth/login']).then();
          }
        })
      );
    }

    this.data = new Login();

    this.form = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  ngOnDestroy(): void {
  }

  forgot() {
    this.router.navigate(['/auth/forgot']).then();
  }

  resolved(captcha: string) {
    this.captcha = captcha;
  }

}
