<div class="container">
  <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-10 offset-lg-1 justify-content-lg-center signin">
      <div class="row mt-5 mb-3">
        <div class="col-md-2">
          <img src="assets/img/logo3.png" width=150 alt="souclou">
        </div>
        <div
          hidden
          class="col-md-7 p-3"
          style="font-size: 22px; font-weight: bold; color: green;"
        >
          Pensée pour vous, vous en avez besoin.
        </div>
        <div hidden class="col-md-3 text-right">
          <button
            (click)="become()"
            class="btn btn-rounded btn-success text-uppercase p-3"
            style="font-weight: bold; font-size: 18px;"
          >
            Devenir client
          </button>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-sm-12 col-lg-6">
          <form
            [formGroup]="form"
            (ngSubmit)="env.allowGooglePlugins ? captchaRef.execute() : login($event)"
            class="bg-success p-5"
          >
            <re-captcha
              #captchaRef="reCaptcha"
              size="invisible"
              [siteKey]="SITE_KEY"
              (resolved)="$event && login($event)"
            ></re-captcha>

            <div
              class="form-group"
              [ngClass]="{'has-error has-danger': form.controls.username.touched && form.controls.username.errors}"
            >
              <label
                class="fa-2x text-black-50"
                for="username"
                style="font-weight: bold;"
              >
                Nom d'utilisateur
              </label>
              <input
                class="form-control form-control-lg"
                formControlName="username"
                autocomplete="username"
                id="username"
                required
              >
              <div
                class="help-block form-text with-errors form-control-feedback"
                *ngIf="form.controls.username.touched && form.controls.username.errors"
              >
                <ul class="list-unstyled">
                  <li *ngIf="form.controls.username.errors.required">Champs obligatoire.</li>
                </ul>
              </div>
            </div>
            <div
              class="form-group"
              [ngClass]="{'has-error has-danger': form.controls.password.touched && form.controls.password.errors}"
            >
              <label
                class="fa-2x text-black-50"
                for="password"
                style="font-weight: bold;"
              >
                Mot de passe
              </label>
              <input
                class="form-control form-control-lg"
                formControlName="password"
                autocomplete="current-password"
                id="password"
                type="password"
                required
              >
              <div
                class="help-block form-text with-errors form-control-feedback"
                *ngIf="form.controls.password.touched && form.controls.password.errors"
              >
                <ul class="list-unstyled">
                  <li *ngIf="form.controls.password.errors.required">Champs obligatoire.</li>
                </ul>
              </div>
            </div>
            <button
              type="submit"
              class="btn btn-secondary text-uppercase p-3"
              style="font-weight: bold; font-size: 18px;"
              [disabled]="form.invalid || loading"
            >
              Accéder à mon compte
            </button>
          </form>
        </div>

        <div class="col-sm-12 col-lg-6 pt-2 pl-5 pr-5">
          <h4 class="mb-3">
            <a routerLink="/auth/forgot">
              <i class="os-icon os-icon-lock"></i>
              Mot de passe oublié
            </a>
          </h4>

          <h4 class="mb-3">
            <i class="os-icon os-icon-help-circle"></i>
            Conseils de sécurité
          </h4>
          <ul class="list-unstyled mb-5">
            <li
              class="mb-3"
              style="font-size: 19px;"
            >
              Vérifiez que l'adresse du site commence exactement par :
              <strong>{{env.origin}}</strong>
              , précédée par une icône cadenas et contient
              un https:// qui garantiront une connexion sécurisée.
            </li>
            <li
              class="mb-3"
              style="font-size: 19px;"
            >
              <a
                class="link"
                href="javascript:;"
              >
                Découvrez nos conseils sécurité
              </a>
              et les
              <a
                class="link"
                href="javascript:;"
              >
                bonnes pratiques
              </a>
              pour
              consulter et identifier les dangers du web.
            </li>
          </ul>

          <h4 class="mb-3">
            <i class="os-icon os-icon-help-circle"></i>
            Informations client
          </h4>
          <ul class="list-unstyled">
            <li
              class="mb-3"
              style="font-size: 19px;"
            >
              Si vous rencontrez des problèmes techniques lors de votre navigation, nous vous
              invitons à contacter notre support au +225 21 38 17 19
            </li>
          </ul>
        </div>
      </div>

      <div class="row pl-3 pr-3 pt-3 pb-1 footer">
        <div class="col-md-2 pt-5 pb-5">
          <img
            src="assets/img/logo3.png"
            width=120
            alt="souclou"
          >
        </div>
        <div class="col-md-3">
          <h6 class="element-header text-white mb-3">{{'Informations légales' | uppercase}}</h6>
          <ul class="list-unstyled">
            <li>
              <a href="javascript:;">Mentions légales</a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://lidar.ci/wp-content/uploads/2020/08/Politiques-Securitaires.pdf"
              >
                Politique de sécurité
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://lidar.ci/wp-content/uploads/2020/09/Politique-de-confidentialite.pdf"
              >
                Politique de confidentialité
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://lidar.ci/wp-content/uploads/2020/09/Tarifs-et-conditions.pdf"
              >
                Tarifs &amp; conditions d'utilisation
              </a>
            </li>
          </ul>
        </div>
        <div class="col-md-2">
          <h6 class="element-header text-white mb-3">{{'Aide' | uppercase}}</h6>
          <ul class="list-unstyled">
            <li>
              <a
                target="_blank"
                href="https://lidar.ci/faq_lidar/"
              >
                FAQ
              </a>
            </li>
            <li>
              <a href="javascript:;">Fiches pratiques</a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://lidar.ci/contact/"
              >
                Nous contacter
              </a>
            </li>
          </ul>
        </div>
        <div class="col-md-3">
          <h6 class="element-header text-white mb-3">{{'Trouver une agence' | uppercase}}</h6>
          <ul class="list-unstyled">
            <li>
              <a href="javascript:;">Trouver l'agence la plus proche</a>
            </li>
          </ul>
        </div>
        <div class="col-md-2">
          <h6 class="element-header text-white mb-3">{{'Nous suivre' | uppercase}}</h6>
          <div class="footer-section_reseaux-sociaux-container mt-3">
            <a
              target="_blank"
              href="https://www.facebook.com/Lidar-C%C3%B4te-dIvoire-115106270356981/"
              class="mr-2"
            >
              <!-- <img src="/rsc/contrib/image/particuliers/homepage/fb.svg" alt="facebook"> -->
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="19"
                viewBox="0 0 8 19"
              >
                <g
                  fill="none"
                  fill-rule="evenodd"
                >
                  <path
                    fill="#FFF"
                    d="M8 8.83H5.356V19H1.678V8.72H0V5.787h1.678c0-1.745-1-6.422 6.286-5.715v3.45c-1.179 0-2.642-.296-2.642 2.265H8v3.042z"
                  ></path>
                </g>
              </svg>
            </a>
            <a
              target="_blank"
              href="https://twitter.com/d_lidar"
              class="mr-2"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="23"
                height="19"
                viewBox="0 0 23 19"
              >
                <g
                  fill="none"
                  fill-rule="evenodd"
                >
                  <path
                    fill="#FFF"
                    d="M7.396 19C3.95 19 .81 17.546 0 16.923c0 0 3.85.727 6.787-2.073-1.518-.518-3.342-.933-4.152-3.318.91.207.91.207 1.823-.103C2.937 10.704 1.621 9.562.81 6.763c.811.414 1.622.621 2.23.621C1.62 5.829.203 3.654 1.518.956c.914.831 4.967 5.288 9.731 4.873 0-2.28.708-4.25 2.329-5.184 1.621-.934 4.359-1.037 5.674.935 1.047.552 2.838.415 3.748 0-.405.83-1.824 2.696-2.532 3.213C20.568 5.622 19.86 19 7.396 19z"
                  ></path>
                </g>
              </svg>
            </a>
            <a
              target="_blank"
              href="javascript:;"
              class="mr-2"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="27"
                height="19"
                viewBox="0 0 27 19"
              >
                <g
                  fill="none"
                  fill-rule="evenodd"
                >
                  <path
                    fill="#FFF"
                    d="M11.713 13.004l-.002-7.59 7.296 3.808-7.294 3.782zM26.73 4.099s-.264-1.861-1.073-2.682C24.63.342 23.479.336 22.952.273 19.173 0 13.506 0 13.506 0h-.012S7.827 0 4.048.273C3.521.336 2.37.342 1.343 1.417.533 2.237.27 4.099.27 4.099S0 6.285 0 8.47v2.049c0 2.187.27 4.372.27 4.372s.264 1.862 1.073 2.681C2.37 18.65 3.72 18.616 4.32 18.73c2.16.207 9.18.271 9.18.271s5.673-.008 9.452-.281c.527-.063 1.678-.07 2.705-1.146.81-.819 1.073-2.68 1.073-2.68s.27-2.186.27-4.373V8.471c0-2.186-.27-4.372-.27-4.372z"
                  ></path>
                </g>
              </svg>
            </a>
            <a
              target="_blank"
              href="https://www.linkedin.com/company/lidar-c%C3%B4te-d-ivoire/"
              class="mr-2"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="19"
                viewBox="0 0 20 19"
              >
                <g
                  fill="none"
                  fill-rule="evenodd"
                >
                  <path
                    fill="#FFFFFE"
                    d="M16.518 16.19h-2.873v-4.41c0-1.05-.02-2.403-1.494-2.403-1.496 0-1.726 1.145-1.726 2.328v4.486H7.553V7.123h2.757v1.239h.039c.384-.713 1.322-1.464 2.72-1.464 2.912 0 3.45 1.878 3.45 4.319v4.974zM4.312 5.885c-.921 0-1.667-.733-1.667-1.635 0-.901.746-1.633 1.667-1.633.92 0 1.667.732 1.667 1.633 0 .902-.747 1.635-1.667 1.635zM2.874 16.19h2.875V7.123H2.874v9.068zM17.95 0H1.43C.642 0 0 .613 0 1.37v16.26C0 18.385.641 19 1.43 19h16.52c.791 0 1.435-.614 1.435-1.37V1.37c0-.757-.644-1.37-1.435-1.37z"
                  ></path>
                </g>
              </svg>
            </a>
          </div>
        </div>
      </div>

      <div
        class="row copyright"
        style="color: white;"
      >
        <div class="col-md-12 text-center p-2">
          <i class="fa fa-copyright"></i>
          Copyright 2020 - Fa &amp; Fa
        </div>
      </div>
    </div>
  </div>

</div>
