import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ForgotComponent } from '@auth/forgot/forgot.component';
import { RecaptchaModule } from 'ng-recaptcha';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ToastModule } from 'primeng/toast';

import { AuthRoutingModule } from './auth-routing.module';
import { AuthComponent } from './auth.component';
import { InviteComponent } from './invite/invite.component';
import { LoginComponent } from './login/login.component';
import { NoaccessComponent } from './noaccess/noaccess.component';
import { SigninComponent } from './signin/signin.component';
import { Signinv2Component } from './signinv2/signinv2.component';


@NgModule({
  declarations: [LoginComponent, AuthComponent, ForgotComponent, InviteComponent, SigninComponent, NoaccessComponent, Signinv2Component],
  bootstrap: [AuthComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    FormsModule,
    ToastModule,
    CheckboxModule,
    AuthRoutingModule,
    InputTextModule,
    PasswordModule,
    RecaptchaModule,
    ReactiveFormsModule,
    ButtonModule,
    ProgressSpinnerModule,
  ]
})
export class AuthModule {
}
