<div class="content-i">
    <div class="content-box">
        <div class="big-error-w">
            <h1>403</h1>
            <h5>Accès réfusé</h5>
            <h4>
                Oops, Cette page ne vous est pas accessible...
            </h4>
            <div class="form-buttons-w">
                <p-button
                    (click)="goHome()"
                    label="Revenir à la page d'accueil"
                    styleClass="ui-button-raised ui-button-primary"
                    type="button"
                >
                </p-button>
            </div>
        </div>
    </div>
</div>
