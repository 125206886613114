import { BehaviorSubject, Observable } from "rxjs";

export abstract class AyStore<T> {
    readonly state$: Observable<T>;
    private _state$: BehaviorSubject<T>;

    constructor(value: T) {
        this._state$ = new BehaviorSubject<T>(value);
        this.state$ = this._state$.asObservable();
    }

    protected get state(): T {
        return this._state$.getValue();
    }

    protected set state(next: T) {
        this._state$.next(next);
    }
}