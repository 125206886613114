<div class="menu-and-user">
  <div class="logged-user-w">
    <app-logged-user-info></app-logged-user-info>
  </div>
  <!--------------------
  START - Mobile Menu List
  -------------------->
  <app-lidar-menu-mobile [items]="items"></app-lidar-menu-mobile>
  <!--------------------
  END - Mobile Menu List
  -------------------->
</div>
