import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { CredentialService } from '@core/credential.service';

@Injectable()
export class ApiPrefixInterceptorService implements HttpInterceptor {

  constructor(
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!/^(http|https):/i.test(request.url)) {
      request = request.clone({ url: environment.serverUrl + request.url, responseType: 'json' });
      console.log('REQ', request.url);

      const credentials = new CredentialService();
      if (credentials.isAuthenticated()) {
        request = request.clone(
          {
            setHeaders: {
              Authorization: `Bearer ${credentials.credentials.token}`
            }
          }
        );
      }
    }

    let already = false;

    if (request.url.includes('moyennes/printBuilletin.json')) {
      window.open(request.urlWithParams, '_blank');
      return null;
    } else {
      environment.uploadUrlEndpoint.forEach(endpoint => {
        if (!already) {
          if (request.url.endsWith(endpoint)) {
            request = request.clone({
              reportProgress: true
            });
          } else {
            request = request.clone({
              headers: request.headers
                .set('Accept', 'application/json')
                .set('Content-Type', 'application/json; charset=UTF-8')
            });
          }
          already = true;
        }
      });
    }

    return next.handle(request);
  }
}
