import { Component, OnInit } from '@angular/core';
import { environment } from '@env/environment';

@Component({
  selector: 'app-side-menu-magic',
  templateUrl: './side-menu-magic.component.html',
  styleUrls: ['./side-menu-magic.component.css']
})
export class SideMenuMagicComponent implements OnInit {

  version = environment;

  constructor() { }

  ngOnInit() {
  }

}
