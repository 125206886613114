export enum EntiteRoles {
  canAdd = 'EntiteAddComponent',
  canEdit = 'EntiteEditComponent',
  canDelete = 'EntiteDeleteComponent',
}

export enum EntrepriseRoles {
  canAdd = 'EntrepriseAdd',
  canView = 'EntrepriseView',
  canEdit = 'EntrepriseEdit',
  canChangeRespo = 'EntrepriseChangeResp',
  canAddProfile = 'EntrepriseAddProfile',
  canChangeStatut = 'EntrepriseChangeStatut',
  canSendTrash = 'EntrepriseSendTrash'
}

export enum FournisseurRoles {
  canList = 'FournisseurCanList'
}

export enum AgenceRoles {
  canAdd = 'AgenceAdd',
  canView = 'AgenceView',
  canEdit = 'AgenceEdit',
  canChangeRespo = 'AgenceChangeResp',
  canChangeStatut = 'AgenceChangeStatut',
  canSendTrash = 'AgenceSendTrash',
}

export enum DistributeurRoles {
  canAdd = 'DistributeurAdd',
  canView = 'DistributeurView',
  canEdit = 'DistributeurEdit',
  canChangeStatut = 'DistributeurChangeStatut',
  canSendTrash = 'DistributeurSendTrash',
}

export enum AccepteurRoles {
  canAdd = 'AccepteurAdd',
  canView = 'AccepteurView',
  canEdit = 'AccepteurEdit',
  canChangeStatut = 'AccepteurChangeStatut',
  canSendTrash = 'AccepteurSendTrash',
}

export enum StockRoles {
  canAdd = 'StockCanAdd',

  canReception = 'StockCanReception',
  canDistribution = 'StockCanDistribution',

  canRetour = 'StockCanRetour',
}

export enum TypeCarteRoles {
  canAdd = 'TypeCarteAddComponent',
  canEdit = 'TypeCarteEditComponent',
  canDelete = 'TypeCarteDeleteComponent',
}

export enum BeneficiaireRoles {
  canAdd = 'BeneficiaireAddComponent',
  canEdit = 'BeneficiaireEditComponent',
  canDelete = 'BeneficiaireDeleteComponent',
  canView = 'BeneficiaireViewComponent',
}

export enum SalarieRoles {
  canAdd = 'SalarieCanAdd',
  canEdit = 'SalarieCanEdit',
  canDelete = 'SalarieCanDelete',
}

export enum FournisseurAgenceRoles {
  canList = 'FournisseurCanList',
  canAdd = 'FournisseurCanAdd',
  canEdit = 'FournisseurCanEdit',
  canDelete = 'FournisseurCanDelete',
}

export enum OperationRoles {
  canRechargeEntreprise = 'OperationCanRechargeEntreprise',
  canRechargeAgence = 'OperationCanRechargeAgence',
  canRechargeCoop = 'OperationCanRechargeCoop',
  canRechargeME = 'OperationCanRechargeME',
  canRechargeFournisseur = 'OperationCanRechargeFournisseur',
  canRechargeMarchand = 'OperationCanRechargeMarchand',
  canRechargeDistributeur = 'OperationCanRechargeDistributeur',
  canMandatAdd = 'OperationCanMandatAdd',
  canMandatReception = 'OperationCanMandatReception',
  canCreditAdd = 'OperationCanCreditAdd',
  canCreditReception = 'OperationCanCreditReception',
  canCreditRembourse = 'OperationCanCreditRembourse',
  canPaySalaire = 'OperationCanPaySalaire',
  canPayPrime = 'OperationCanPayPrime',
}

export enum WfRoles {
  canReceiveSMS = 'OperationWfReceiveSMS',
  canValidateOp = 'OperationWfValidateOp',
  canOraOp = 'OperationOraOp',
}

export enum CommissionRoles {
  canAdd = 'CommissionAddComponent',
  canEdit = 'CommissionEditComponent',
}

export enum OpBordChampRoles {
  canAdd = 'CommissionAdd',
  canView = 'CommissionView',
  canValidate = 'CommissionValidate',
  canChangeStatut = 'CommissionChangeStatut',
}

export enum DelegueRoles {
  canAdd = 'DelegueAddComponent',
  canDelete = 'DelegueDeleteComponent',
}

export enum ProducteurRoles {
  canAdd = 'ProducteurAddComponent',
  canDelete = 'ProducteurDeleteComponent',
}

export enum ReportingRoles {
  canManage = 'ReportingCanManage',
}

export enum BudgetPrevisionnelRoles {
  canEntiteAdd = 'BudPrevCanEntiteAdd',
  canEntiteEdit = 'BudPrevCanEntiteEdit',
  canEntiteDelete = 'BudPrevCanEntiteDelete',
  canEntiteChangeState = 'BudPrevCanEntiteChangeState',
  canAgenceAdd = 'BudPrevCanAgenceAdd',
  canAgenceEdit = 'BudPrevCanAgenceEdit',
  canAgenceDelete = 'BudPrevCanAgenceDelete',
}
