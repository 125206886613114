import { CommonModule } from '@angular/common';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { RouterModule } from '@angular/router';
import { httpInterceptorProviders } from '@core/index';
import { FilterPipe } from './api/pipe/filter.pipe';

@NgModule({
  providers: [
    httpInterceptorProviders
  ],
  declarations: [FilterPipe],
  exports: [],
  imports: [
    CommonModule,
    RouterModule
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    // Import guard
    if (parentModule) {
      throw new Error(`${parentModule} has already been loaded. Import Core module in the AppModule only.`);
    }
  }
}
