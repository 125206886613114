<div class="mm-logo-buttons-w">
    <a
        class="mm-logo"
        routerLink="/"
    >
        <span>{{'Lidar Platform' | uppercase}}</span>
    </a>
    <div class="mm-buttons">
        <div class="content-panel-open">
            <div class="os-icon os-icon-grid-circles"></div>
        </div>
        <div class="mobile-menu-trigger">
            <div class="os-icon os-icon-hamburger-menu-1"></div>
        </div>
    </div>
</div>
