<div class="auth-box-w">
  <div class="logo-w">
    <a routerLink="/">
      <img
        width=200
        src="assets/img/logo3.png"
      >
    </a>
  </div>
  <h4 class="auth-header">Mot de passe oublié ?</h4>
  <form
    [formGroup]="form"
    (ngSubmit)="captchaRef.execute()"
  >
    <re-captcha
      size="invisible"
      #captchaRef="reCaptcha"
      [siteKey]="SITE_KEY"
      (resolved)="$event && requestLogin($event)"
    ></re-captcha>

    <div class="form-group"
         [ngClass]="{'has-error has-danger': form.get('username').touched && form.get('username').errors}">
      <label
        for="username"
        translate="yes"
        i18n
      >
        Nom d'utilisateur
      </label>
      <input
        formControlName="username"
        class="form-control"
        id="username"
        name="username"
        pInputText
        placeholder="Saisir votre nom d'utilisateur"
        required
      >
      <div
        class="help-block form-text with-errors form-control-feedback"
        *ngIf="form.get('username').touched && form.get('username').errors"
      >
        <ul class="list-unstyled">
          <li *ngIf="form.get('username').errors.required">Champs obligatoire.</li>
        </ul>
      </div>
    </div>
    <div class="form-group"
         [ngClass]="{'has-error has-danger': form.get('name').touched && form.get('name').errors}">
      <label
        for="email"
        translate="yes"
        i18n
      >
        E-mail
      </label>
      <input
        formControlName="name"
        class="form-control"
        email
        id="email"
        name="email"
        pInputText
        placeholder="Saisir votre adresse mail"
        required
      >
      <div
        class="help-block form-text with-errors form-control-feedback"
        *ngIf="form.get('name').touched && form.get('name').errors"
      >
        <ul class="list-unstyled">
          <li *ngIf="form.get('name').errors.required">Champs obligatoire.</li>
        </ul>
      </div>
    </div>
    <div class="buttons-w">
      <button
        [disabled]="!form.valid || isLoading"
        class="btn btn-primary"
        type="submit"
      >Réinitialiser
      </button>
    </div>
    <div class="buttons-w mt-3 text-center">
      <button
        type="button"
        class="btn btn-link"
        (click)="login()"
      >
        Page de connexion
      </button>
    </div>
  </form>
</div>
