import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthorizationService } from './authorization.service';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[canActionAccess]'
})
export class CanActionAccessDirective implements OnInit {

  @Input('canActionAccess') permissionList: Array<string>;

  constructor(
    private authorization: AuthorizationService,
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<any>
  ) { }

  ngOnInit(): void {
    this.apply();
  }

  private apply(): void {
    if (this.authorization.canAccess(this.permissionList)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

}
