import { Component, OnInit } from '@angular/core';
import { CredentialService } from '@core/credential.service';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.css']
})
export class LogoComponent implements OnInit {

  appName: string;

  constructor(
    private credential: CredentialService
  ) { }

  ngOnInit() {
    if (this.credential.isMulti()) {
      this.appName = 'Lidar App';
    }
    if (this.credential.isEntite()) {
      this.appName = this.credential.credentials.user.entite_user.entite.name;
    }
    if (this.credential.isAgence()) {
      this.appName = this.credential.credentials.user.agence_user.agence.name;
    }

    // this.appName = 'Lidar Platform';
    this.appName = 'Souclou Digital';
  }

}
